import PropTypes from 'prop-types';
import { Box, Stack } from '@mui/material';
import Editor from '../../../../../reusable-components/editor';
import { postAddTicketImage } from '../../../../../api/tickets';
import FileThumbnail from '../../../../../reusable-components/file-thumbnail';

TicketEditor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  editorProps: PropTypes.object,
  users: PropTypes.array,
  attachments: PropTypes.array,
  setAttachments: PropTypes.func,
};

export default function TicketEditor({ value, onChange, editorProps, users, attachments, setAttachments })
{
  const handleFileInputChange = (event) => {
    const maxAllowedFiles = 5;
    if (event.target.files.length > maxAllowedFiles || event.target.files.length + attachments?.length > maxAllowedFiles) {
      alert(`Only ${maxAllowedFiles} files can be uploaded at a time.`);
      event.target.value = '';
      return;
    }
    const { files } = event.target;
    const fileList = [];
    for (let i = 0; i < files.length; i++) {
      if (attachments?.some((x) => x.name === files[i].name)) alert(`File ${files[i].name} already exists.`);
      else fileList.push(files[i]);
    }
    setAttachments((prevAttachments) => [...(prevAttachments || []), ...fileList]);
  };

  const removeFile = (file) => () => setAttachments((prevAttachments) => prevAttachments.filter((x) => x !== file));

  const handleChangeMessage = async (newValue) => {
    newValue = newValue?.replace(/<h1>/g, '<b>').replace(/<\/h1>/g, '</b>')
      .replace(/<h2>/g, '<b>').replace(/<\/h2>/g, '</b>')
      .replace(/<h3>/g, '<b>').replace(/<\/h3>/g, '</b>')
      .replace(/<h4>/g, '<b>').replace(/<\/h4>/g, '</b>')
      .replace(/<h5>/g, '<b>').replace(/<\/h5>/g, '</b>')
      .replace(/<h6>/g, '<b>').replace(/<\/h6>/g, '</b>');

    if (newValue.includes('<img src=')) {
      const imgSrcRegex = /<img src="([^"]*)"/g;
      const imgSrcMatch = newValue.matchAll(imgSrcRegex);
      const imgSrcArray = Array.from(imgSrcMatch, (match) => ({ src: match[1] })).filter(img => !img.src.includes('//res.cloudinary.com/agoraserv'));
      if (imgSrcArray.length > 0)
      {
        await imgSrcArray.forEach(async (img) => {
            const imgUrl = await postAddTicketImage(img.src);
          newValue = newValue.replace(img.src, imgUrl.data);
        });
      }      
    }
    onChange(newValue)
  };

  const attachFile = editorProps?.attachFile ?? true;

  return <>
    <Editor    
      simple
      value={value || "<p></p>"}
      users={users}      
      onChange={(value) => handleChangeMessage(value)}
      sx={{ flexGrow: 1, borderColor: 'transparent', width: '100%' }}
      handleFileInputChange={attachFile ? handleFileInputChange : null}
      {...(editorProps || {})}
      attachFile={attachFile}
    />
    {attachFile && attachments?.length > 0 && <Box sx={{ width: '100%' }}>
      <Stack direction="row" alignItems="center" spacing={1}>
        {attachments.map((file) => (
          <FileThumbnail
            key={file.name}
            file={file}
            tooltip
            sx={{ width: 64, height: 64 }}
            onRemove={removeFile(file)}
          />
        ))}
      </Stack>
    </Box>}
  </>;
                 

}