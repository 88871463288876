import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Grid, Paper, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { postDeleteFacility, postFacilityForm } from '../../../../api/facility';
import { AwaitButton } from '../../../../reusable-components/await-button';
import { ConfirmDialog } from '../../../../reusable-components/confirm-dialog';
import FormDropdown from '../../../../reusable-components/form/FormDropdown';
import FormTextWithValue from '../../../../reusable-components/form/FormTextWithValue';
import Iconify from '../../../../reusable-components/iconify';
import { ScopeGuard } from '../../../../reusable-components/scopes';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { FAC_STRUCTURE, FIFTY_STATES, PAY_METHOD, TRUE_FALSE } from '../../../../utils/dropdowns';
import FacilityLogoUpload from './FacilityLogoUpload';

FacilityTab.propTypes = {
  selectedRow: PropTypes.object,
  companies: PropTypes.array,
  data: PropTypes.array,
  fetchData: PropTypes.func,
  setTab: PropTypes.func,
  setSelectedRow: PropTypes.func,
  isAddFacilityModal: PropTypes.bool,
};

export default function FacilityTab({
  selectedRow,
  companies,
  data,
  fetchData,
  setTab,
  setSelectedRow,
  isAddFacilityModal,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [edit, setEdit] = useState(isAddFacilityModal || false);
  const [attachment, setAttachment] = useState(
    isAddFacilityModal
      ? null
      : {
          attachment: selectedRow?.siteMapFile,
        }
  );
  const [value, setValue] = useState(
    isAddFacilityModal
      ? {
          facilityName: '',
          shortName: '',
          abbreviation: '',
          streetAddress: '',
          city: '',
          state: '',
          zip: '',
          county: '',
          phoneNumber: '',
          beds: '',
          parentFacility: '',
          company: '',
          taxExempt: null,
          facStructure: '',
          apPhone: '',
          EIN: '',
          subgroup: '',
          invoiceEmail: '',
          repName: '',
          repEmail: '',
          payMethod: '',
        }
      : {
          facilityName: selectedRow?.facilityName || '',
          shortName: selectedRow?.shortName || '',
          abbreviation: selectedRow?.abbreviation || '',
          streetAddress: selectedRow?.streetAddress || '',
          city: selectedRow?.city || '',
          state: selectedRow?.state || '',
          zip: selectedRow?.zip || '',
          county: selectedRow?.county || '',
          phoneNumber: selectedRow?.phoneNumber || '',
          beds: selectedRow?.beds || '',
          parentFacility:
            {
              id: selectedRow?.parentFacilityId,
              label:
                data.find((d) => d.id === selectedRow?.parentFacilityId)?.facilityName ||
                data.find((d) => d.id === selectedRow?.parentFacilityId)?.shortName,
            } || '',
          company:
            {
              value: selectedRow?.companyId,
              label: companies.find((company) => company.value === selectedRow?.companyId)?.label,
            } || '',
          taxExempt: selectedRow?.taxExempt || null,
          facStructure: selectedRow?.facStructure || '',
          apPhone: selectedRow?.apPhone,
          EIN: selectedRow?.einNumber,
          subgroup: selectedRow?.subgroup,
          invoiceEmail: selectedRow?.invoiceEmail,
          repName: selectedRow?.repName,
          repEmail: selectedRow?.repEmail,
          payMethod: selectedRow?.payMethod,
          logo: selectedRow?.logo,
        }
  );

  const handleEdit = () => {
    setEdit(true);
  };
  const handleDeleteFile = () => {
    setAttachment();
  };
  const handleEditSave = async () => {
    const response = await postFacilityForm({ form: value, id: selectedRow?.id, attachment });
    if (response.data.error) {
      enqueueSnackbar(`Error: ${response.data.error}`, {
        variant: 'error',
      });
    } else if (response.status === 200) {
      enqueueSnackbar(`Successfully updated ${response.data.facilityName || response.data.shortName}`, {
        variant: 'success',
      });
      fetchData();
      setEdit(false);
    } else
      enqueueSnackbar(`Error: Could not save facility`, {
        variant: 'error',
      });
  };

  const handleSave = async () => {
    const response = await postFacilityForm({ form: value, attachment });
    if (response.data.error) {
      enqueueSnackbar(`Error: ${response.data.error}`, {
        variant: 'error',
      });
    } else if (response.status === 200) {
      enqueueSnackbar(`Successfully added ${response.data.facilityName}`, {
        variant: 'success',
      });
      fetchData();
    } else
      enqueueSnackbar(`Error: Could not add facility`, {
        variant: 'error',
      });
  };
  const handleDelete = async (id) => {
    const response = await postDeleteFacility(selectedRow?.id);
    if (response) {
      enqueueSnackbar(`Successfully deleted ${selectedRow?.facilityName}`, {
        variant: 'success',
      });
      setTab(0);
      setSelectedRow(null);
      fetchData();
    }
  };

  const handleFileInputChange = (event) => {
    if (event.target.files.length > 1 || event.target.files.length + attachment?.length > 1) {
      alert(`Only 1 file can be uploaded at a time.`);
      event.target.value = '';
      return;
    }
    setAttachment(event.target.files[0]);
  };
  const onDrop = (acceptedFiles) => {
    handleFileInputChange({ target: { files: acceptedFiles } });
  };
  const setLogo = (newLogo) => {
    setValue((oldVal) => ({ ...oldVal, logo: newLogo }));
  };
  const { open: openDropzone } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
  });

  return (
    <Paper sx={{ py: 2, px: 4 }}>
      <Grid item xl={12}>
        <Grid item xs={8}>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
            <Grid item sx={{ mb: 6, mt: 3 }}>
              <Box>
                <Typography variant="subtitle1" sx={{ mb: 0.5 }}>
                  Facility Logo
                </Typography>
                <FacilityLogoUpload edit={edit} logo={value.logo} setLogo={setLogo} />
              </Box>
            </Grid>
            {!isAddFacilityModal && (
              <Grid item sx={{ mr: 3, my: 3 }}>
                <Typography variant="h3" color="info.main" display="inline">
                  {selectedRow?.facilityName}
                </Typography>
              </Grid>
            )}
            <ScopeGuard scopes={['Super-Admin']}>
              <Grid item>
                <Box>
                  <>
                    {edit ? (
                      <>
                        <Button
                          variant="outlined"
                          color="info"
                          size="small"
                          disabled={value.name === ''}
                          sx={{ mr: 1 }}
                          onClick={isAddFacilityModal ? handleSave : handleEditSave}
                        >
                          Save {isAddFacilityModal ? 'New Facility' : 'Changes'}
                        </Button>
                        {!isAddFacilityModal && (
                          <Button
                            variant="outlined"
                            size="small"
                            color="error"
                            onClick={() => {
                              setEdit(false);
                            }}
                          >
                            Cancel
                          </Button>
                        )}
                      </>
                    ) : (
                      <>
                        <AwaitButton
                          variant="outlined"
                          color="info"
                          size="small"
                          startIcon={<EditIcon />}
                          sx={{ mr: 1 }}
                          onClick={handleEdit}
                        >
                          Edit
                        </AwaitButton>

                        <ConfirmDialog
                          icon="delete-outline"
                          title="Delete"
                          color="error"
                          content={`Are you sure you want to delete - ${selectedRow?.facilityName}?`}
                          actionButton="Delete"
                          action={() => handleDelete()}
                        />
                      </>
                    )}
                  </>
                </Box>
              </Grid>
            </ScopeGuard>
          </Grid>
        </Grid>

        <Box>
          <Grid>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
              <FormTextWithValue
                disabled={!edit}
                value={value}
                title="Facility Name"
                flex={6}
                name="facilityName"
                setValue={setValue}
              />
              <FormTextWithValue
                disabled={!edit}
                value={value}
                title="Short Name"
                flex={3}
                name="shortName"
                setValue={setValue}
              />
              <FormTextWithValue
                disabled={!edit}
                value={value}
                title="Abbreviation"
                flex={3}
                name="abbreviation"
                setValue={setValue}
              />

              <Grid item xs={4}>
                <FormDropdown
                  title="Group"
                  disabled={!edit}
                  fullWidth
                  value={value.company}
                  onChange={(event, newValue) => {
                    setValue({
                      ...value,
                      company: newValue,
                    });
                  }}
                  options={companies}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  getOptionLabel={(option) => option?.label || ''}
                  renderInput={(params) => <TextField {...params} label="Search or select" />}
                />
              </Grid>
              <Grid item xs={4}>
                <FormDropdown
                  title="Parent Facility"
                  disabled={!edit}
                  fullWidth
                  value={value.parentFacility}
                  onChange={(event, newValue) => {
                    setValue({
                      ...value,
                      parentFacility: newValue,
                    });
                  }}
                  options={data?.map((type) => ({ id: type.id, label: type.shortName || type.facilityName }))}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  getOptionLabel={(option) => option?.label || ''}
                  renderInput={(params) => <TextField {...params} label="Search or select" />}
                  sx={{
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    '& .MuiFormLabel-root ': { display: 'none' },
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <FormTextWithValue
                  disabled={!edit}
                  value={value}
                  title="Subgroup"
                  name="subgroup"
                  setValue={setValue}
                />
              </Grid>
              <Grid item xs={3}>
                <FormDropdown
                  disabled={!edit}
                  value={
                    FAC_STRUCTURE.find((facStructure) => facStructure.value === value.facStructure) || {
                      label: value.facStructure,
                      value: value.facStructure,
                    }
                  }
                  title="Facility Structure"
                  fullWidth
                  onChange={(event, newValue) => {
                    setValue({ ...value, facStructure: newValue?.value ?? null });
                  }}
                  options={FAC_STRUCTURE}
                  getOptionLabel={(option) => option?.label || ''}
                  renderInput={(params) => <TextField {...params} label="Search or select" />}
                  flex={1}
                />
              </Grid>
              <FormTextWithValue disabled={!edit} value={value} title="EIN #" flex={3} name="EIN" setValue={setValue} />
              <Grid item xs={3}>
                <FormDropdown
                  disabled={!edit}
                  value={
                    TRUE_FALSE.find((taxExempt) => taxExempt.value === value.taxExempt) || {
                      label: value.taxExempt,
                      value: value.taxExempt,
                    }
                  }
                  title="Tax Exempt"
                  fullWidth
                  onChange={(event, newValue) => {
                    setValue({ ...value, taxExempt: newValue?.value ?? null });
                  }}
                  options={TRUE_FALSE}
                  getOptionLabel={(option) => option?.label || ''}
                  renderInput={(params) => <TextField {...params} label="Search or select" />}
                />
              </Grid>
              <FormTextWithValue disabled={!edit} value={value} title="Beds" flex={3} name="beds" setValue={setValue} />

              <FormTextWithValue
                disabled={!edit}
                value={value}
                title="Address"
                flex={8}
                name="streetAddress"
                setValue={setValue}
              />
              <FormTextWithValue disabled={!edit} value={value} title="City" flex={4} name="city" setValue={setValue} />
              <Grid item xs={3}>
                <FormDropdown
                  disabled={!edit}
                  title="State"
                  fullWidth
                  value={
                    FIFTY_STATES.find((state) => state.value === value.state) || {
                      label: value.state,
                      value: value.state,
                    }
                  }
                  onChange={(event, newValue) => {
                    setValue({ ...value, state: newValue.value });
                  }}
                  options={FIFTY_STATES}
                  getOptionLabel={(option) => option?.label || ''}
                  renderInput={(params) => <TextField {...params} label="Search or select" />}
                />
              </Grid>
              <FormTextWithValue disabled={!edit} value={value} title="Zip" flex={3} name="zip" setValue={setValue} />
              <FormTextWithValue disabled={!edit} value={value} title="County" flex={3} name="county" setValue={setValue} />
              <FormTextWithValue
                disabled={!edit}
                value={value}
                title="Phone Number"
                flex={3}
                name="phoneNumber"
                setValue={setValue}
              />
              <Grid container mt={3} ml={2}>
                <Grid item>
                  <Button
                    variant="outlined"
                    disabled={!edit}
                    size="small"
                    color="primary"
                    sx={{ mr: 1 }}
                    onClick={openDropzone}
                    startIcon={<Iconify icon="material-symbols:upload" />}
                  >
                    Site Map
                  </Button>
                </Grid>
                <Grid item>
                  {attachment && attachment.attachment !== null && (
                    <Grid container alignItems="center">
                      <Grid item>
                        {edit && (
                          <Button
                            sx={{ mr: -3 }}
                            onClick={() => handleDeleteFile()}
                            startIcon={
                              <Iconify icon="material-symbols:delete-outline" width={20} height={20} color="error" />
                            }
                          >
                            {' '}
                          </Button>
                        )}
                      </Grid>
                      <Grid item>
                        <Typography color={!edit ? 'gray' : 'black'} sx={{ textDecoration: 'underline' }}>
                          {attachment.attachment ?? attachment.path}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
              <Typography
                variant="subtitle1"
                color="info.main"
                sx={{ mb: 2, fontWeight: 'bold', fontSize: '23px', mt: 6, ml: 2 }}
              >
                Billing Information:
              </Typography>
            </Grid>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
              <FormTextWithValue
                disabled={!edit}
                value={value}
                title="Email to Send Invoice To"
                flex={3}
                name="invoiceEmail"
                setValue={setValue}
              />
              <FormTextWithValue
                disabled={!edit}
                value={value}
                title="AP Rep Name"
                flex={3}
                name="repName"
                setValue={setValue}
              />
              <FormTextWithValue
                disabled={!edit}
                value={value}
                title="AP Rep Email"
                flex={3}
                name="repEmail"
                setValue={setValue}
              />
              <FormTextWithValue
                disabled={!edit}
                value={value}
                title="AP Rep Phone #"
                flex={3}
                name="apPhone"
                setValue={setValue}
              />
              <Grid item xs={5} mb={2}>
                <FormDropdown
                  disabled={!edit}
                  title="Payment Method"
                  value={
                    TRUE_FALSE.find((payMethod) => payMethod.value === value.payMethod) || {
                      label: value.payMethod,
                      value: value.payMethod,
                    }
                  }
                  fullWidth
                  onChange={(event, newValue) => {
                    setValue({ ...value, payMethod: newValue?.value ?? null });
                  }}
                  options={PAY_METHOD}
                  getOptionLabel={(option) => option?.label || ''}
                  renderInput={(params) => <TextField {...params} label="Search or select" />}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Paper>
  );
}
