import { styled } from '@mui/system';
import { AppBar, Grid, Button, IconButton, Box } from '@mui/material';
import { useSelector } from '../../../redux/store';
import Iconify from '../../../reusable-components/iconify';
import { useTabs } from '../../../context/TabContext';

const CustomAppBar = styled(AppBar)(() => ({
  flexGrow: 1,
  width: '100%',
  color: 'inherit',
  backgroundColor: '#DFE3E8',
  marginTop: '-20px',
  boxShadow: 'none',
  minHeight: '42px',
  height: '42px',
  maxHeight: '42px',
}));

const shortTitle = (text, url) => {
  // Add url parameter
  //remove the letters " | Platform Solutions" from the end of the title
  const title = text.replace(' | Platform Solutions', '');
  //split the title into an array of words
  const titleArray = title.split(' ');

  // Check if title is "Order" or "Ticket"
  if (['Order', 'Ticket'].includes(titleArray[0])) {
    const match = url.match(/\/(\d+)/); // Extract number from URL
    if (match) {
      return `${titleArray[0]} #${match[1]}`; // Append # followed by the number to the title
    }
  }

  //if the title is longer than 3 words, return the first 3 words with a ... at the end
  if (titleArray.length > 3) {
    return `${titleArray[0]} ${titleArray[1]} ${titleArray[2]}...`;
  }
  //otherwise return the title as is
  return title;
};

const CustomTabs = () => {
  const { ssoLogin } = useSelector((state) => state.user.user);

  const { tabList, value, handleTabChange, addTab, deleteTab } = useTabs();
  return (
    <CustomAppBar position="static">
      <Grid container direction="row" justifyContent="flex-start" alignItems="center">
        {ssoLogin && (
          <Grid item>
            <Button
              key="sso"
              onClick={() => window.open('https://app.agoraserv.com', '_self')}
              variant={'contained'}
              sx={{
                zIndex: 9999,
                borderBottom: 'none',
                borderLeft: 'none',
                borderRight: 'none',
                borderTop: 'none',
                borderRadius: '5px 5px 0px 0px',
                backgroundColor: '#DFE3E8',
                color: '#000',
                minHeight: '43px',
                height: '43px',
                maxHeight: '43px',
                bottomBorderRadius: '0px',
                // marginRight: '2px',
                marginLeft: '4px',
                mt: '-3px',
                boxShadow: 'none',
                //no styles on hover
                '&:hover': {
                  backgroundColor: '#F4F6F8',
                  color: '#000',
                  borderBottom: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  borderTop: 'none',
                },
              }}
            >
              <Box component="img" src="/assets/agora-logo.png" sx={{ height: 25 }} />
            </Button>
          </Grid>
        )}
        <Grid item>
          {tabList.map((tab) => (
            <Button
              key={tab.key.toString()}
              onClick={() => handleTabChange(tab.id)}
              variant={value === tab.id ? 'outlined' : 'contained'}
              sx={{
                //remove bottom border from selected tab
                zIndex: 9999,
                borderBottom: 'none',
                borderLeft: 'none',
                borderRight: 'none',
                borderTop: 'none',
                borderRadius: '5px 5px 0px 0px',
                backgroundColor: value === tab.id ? '#F4F6F8' : '#DFE3E8',
                color: '#000',
                minHeight: '43px',
                height: '43px',
                maxHeight: '43px',
                bottomBorderRadius: '0px',
                // marginRight: '2px',
                marginLeft: '4px',
                mt: '-3px',
                boxShadow: 'none',
                //no styles on hover
                '&:hover': {
                  backgroundColor: '#F4F6F8',
                  color: '#000',
                  borderBottom: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  borderTop: 'none',
                },
              }}
              endIcon={
                <Iconify icon="mdi:close" id={tab.id} onClick={(e) => deleteTab(e, tab.id)} width={20} height={20} />
              }
            >
              {shortTitle(tab.title, tab.url)}
            </Button>
          ))}
        </Grid>
        <Grid item>
          <IconButton
            onClick={addTab}
            sx={{
              opacity: 0.8,
            }}
          >
            <Iconify icon="mdi:plus" width={28} height={28} />
          </IconButton>
        </Grid>
      </Grid>
    </CustomAppBar>
  );
};

export default CustomTabs;
