import { React, useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import PropTypes from 'prop-types';
import { FormControl, MenuItem, Select, Autocomplete, Stack, Typography, Box, Button, TextField } from '@mui/material';
import { AdapterDayjs, DateCalendar, DateField, DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { fMonthDayYearShort } from '../../utils/formatTime';
import { fCurrency } from '../../utils/formatNumber';
import Iconify from '../iconify';
import { useScopeCheck } from '../scopes';

FieldMapping.propTypes = {
  fieldName: PropTypes.string,
  name: PropTypes.string, //name of the value
  fieldType: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  control: PropTypes.any,
};

export default function FieldMapping({
  fieldName,
  name,
  control,
  fieldType,
  onChange,
  options = [],
  disabled = false,
}) {
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const hasEditAccess = useScopeCheck(['Invoice-Edit'], true);
  const handleFocus = (e) => {
    setIsEditing(true);
    e.target.select();
  };

  const handleBlur = () => {
    setIsEditing(false);
  };
  const {
    field,
    formState: { errors },
  } = useController({ name, control });

  const fieldStyle = {
    fontSize: '16px',
    fontWeight: '700',
    display: 'inline-block',
    whiteSpace: 'nowrap',
  };
  const valueStyle = {
    fontSize: '14px',
    color: '#060606',
  };

  return (
    <>
      <Box>
        <Stack spacing={1} direction={'row'} alignItems={'center'}>
          <Typography sx={fieldStyle} variant="standard" color={errors?.[name] ? 'error' : '#060606'}>
            {fieldName}:
          </Typography>
          {(fieldType === 'text' || fieldType === 'currency') && (
            <TextField
              name={name}
              variant="standard"
              onChange={(e) => {
                field.onChange();
                onChange(e);
              }}
              value={fieldType === 'currency' && !isEditing ? fCurrency(field.value) : field.value}
              onFocus={fieldType === 'currency' ? handleFocus : undefined}
              onBlur={fieldType === 'currency' ? handleBlur : undefined}
              disabled={!hasEditAccess || disabled}
              inputRef={field.ref}
            />
          )}
          {fieldType === 'autocomplete' && (
            <Autocomplete
              value={field.value ? options?.find((option) => option.value === field.value) : null}
              onChange={onChange}
              options={options}
              disableClearable
              sx={{ fontSize: '12px', flexGrow: 1 }}
              disabled={!hasEditAccess || disabled}
              renderInput={(params) => (
                <TextField
                  inputRef={field.ref}
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      valueStyle,
                    },
                  }}
                  variant="standard"
                />
              )}
            />
          )}
          {fieldType === 'select' && (
            <FormControl variant="standard" sx={{ flexGrow: 1 }} inputRef={field.ref}>
              <Select
                style={valueStyle}
                value={field.value || ''}
                onChange={onChange}
                disabled={!hasEditAccess || disabled}
                inputRef={field.ref}
              >
                {options?.map((item) => (
                  <MenuItem style={{ fontSize: '12px' }} value={item?.value}>
                    {item?.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {fieldType === 'date' && (
            <Button
              color="secondary"
              disabled={!hasEditAccess || disabled}
              onClick={() => setCalendarOpen(!calendarOpen)}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Iconify color="secondary" sx={{ mr: '5px' }} icon="mdi:calendar" />
                {field.value !== '' && field.value !== undefined ? (
                  <Typography
                    variant="subtitle2"
                    color="secondary"
                    component="span"
                    sx={{ textDecoration: 'underline' }}
                  >
                    {fMonthDayYearShort(field.value)}
                  </Typography>
                ) : (
                  <Typography
                    variant="subtitle2"
                    color="secondary"
                    component="span"
                    sx={{ textDecoration: 'underline' }}
                  >
                    Choose Date
                  </Typography>
                )}
              </Box>
            </Button>
          )}
        </Stack>
      </Box>
      {calendarOpen && (
        <DateCalendar
          inputRef={field.ref}
          disabled={!hasEditAccess || disabled}
          sx={{ boxShadow: '0 8px 16px 0 rgb(205 198 206 / 24%)' }}
          value={field.value !== '' ? new Date(field.value) : null}
          onChange={(newDate) => {
            onChange(newDate);
            setCalendarOpen(false);
          }}
          onClose={() => setCalendarOpen(false)}
        />
      )}
    </>
  );
}
