import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tooltip, IconButton, Box, Typography, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DataGridPro } from '@mui/x-data-grid-pro';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { useSelector, useDispatch } from '../../redux/store';
import { openErrorDialog } from '../../redux/slices/orders';
import { getConnectedItems } from '../../api/orders';
import { REPLACE_VENDOR_ITEM, VENDOR_ITEM } from './pendingOrderColumns';
import ReplaceItemQuantityField from './ReplaceItemQuantityField';
import { useScopeCheck } from '../scopes';
import Iconify from '../iconify';
import CustomDataGrid from './CustomDataGrid';

VendorField.propTypes = {
  row: PropTypes.object,
};

export default function VendorField({ row }) {
  const dispatch = useDispatch();

  const hasAccess = useScopeCheck(['Edit-Order'], true);
  const { id = '' } = useParams();
  const { cartCheckItemsById } = useSelector((state) => state.orders || {});
  const errorArray = useMemo(() => {
    const cartCheckItems = cartCheckItemsById?.[id];
    if (!cartCheckItems?.length) return [];
    console.log('cartCheckItems', cartCheckItems);
    const cartCheckItem = cartCheckItems?.find((cci) => cci.id === row.id) || {};
    console.log('cartCheckItem', cartCheckItem);

    return cartCheckItem && cartCheckItem.errors ? cartCheckItem.errors : [];
  }, [cartCheckItemsById, id, row]);

  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);

  const handleClickOpen = async (productId) => {
    const response = await getConnectedItems(productId, id);
    if (response.status === 200) {
      const filterOutCurrentItem = response.data.filter((item) => item.id !== row.itemId);
      setRows(filterOutCurrentItem);
    }

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const openDialog = () => {
    dispatch(openErrorDialog());
  };

  const qtyTotalAction = {
    field: 'qtyTotalAction',
    headerName: 'Qty',
    width: 450,
    renderCell: (params) => <ReplaceItemQuantityField errorRow={row} row={params.row} handleClose={handleClose} />,
    id: 'qty',
    className: 'qty',
  };
  return (
    <>
      <Box
        sx={{
          display: 'flex', // Use flexbox
          '&:hover': {
            cursor: errorArray.length > 0 ? 'pointer' : 'default',
          },
        }}
      >
        <Tooltip
          title={
            errorArray.length > 0
              ? errorArray.map((error) => (
                  <div key={error}>
                    {error}
                    <br />
                  </div>
                ))
              : ''
          }
        >
          <Typography
            variant="subtitle2"
            color="secondary"
            component="span" // Use span here to prevent nested paragraphs
            onClick={errorArray?.length > 0 ? openDialog : null}
            sx={{
              textDecoration: errorArray.length > 0 ? 'underline' : null,
              color: errorArray.length > 0 ? 'error.main' : null,
              mt: 0.5,
              mr: 0.2,
            }}
          >
            {row.vendor || 'N/A'}
          </Typography>
        </Tooltip>
        {row.hasConnectedItems && hasAccess && (
          <Tooltip title="Change vendor">
            <Typography component="span">
              <IconButton
                color="secondary"
                onClick={() => handleClickOpen(row.productId)}
                sx={{
                  padding: '0px',
                }}
              >
                <SwapHorizIcon />
              </IconButton>
            </Typography>
          </Tooltip>
        )}
      </Box>
      <Dialog open={open} onClose={handleClose} maxWidth="full" fullWidth>
        <DialogTitle
          sx={{
            textAlign: 'center',
          }}
        >
          Change Vendor
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
            Current item
          </Typography>
          <div style={{ width: '100%' }}>
            <StyledDataGrid
              getRowHeight={() => 'auto'}
              rows={[row]}
              columns={REPLACE_VENDOR_ITEM}
              getEstimatedRowHeight={() => 44}
              disableRowSelectionOnClick
            />
          </div>
          <Typography variant="subtitle1" sx={{ mt: 2, textAlign: 'center' }}>
            Change to
          </Typography>
          <div style={{ width: '100%' }}>
            <CustomDataGrid
              gridId="vendorFieldDataGrid"
              getRowHeight={() => 'auto'}
              data={rows}
              gridColumns={[...VENDOR_ITEM, qtyTotalAction]}
              disableRowSelectionOnClick
              sx={{
                '& .MuiDataGrid-cell': {
                  cursor: 'default !important',
                },
              }}
              isModal
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
const StyledDataGrid = styled(DataGridPro)(() => ({
  '& .MuiDataGrid-main': {
    // borderRadius: '10px',
    backgroundColor: '#fff',
  },
  //   '& .MuiDataGrid-columnHeaders': {
  //     display: 'none',
  //   },
  '& .MuiDataGrid-row': {
    border: 'none',
    backgroundColor: '#fff',
  },
  '& .MuiDataGrid-row:hover': {
    cursor: 'default',
    backgroundColor: '#fff',
  },
}));
