import { createSlice } from '@reduxjs/toolkit';
import { login, logoutUser, updateUser, refreshLoginData, updateUserConfig } from '../../api/account';
import { loginAsUser } from '../../api/user';
import { postSiteSwitch } from '../../api/home';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  user: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    ssoLogin: false,
    siteId: 0,
    settings: [],
    sites: [],
    roles: [],
  },
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    loginSuccess(state, action) {
      if (action.payload.success) {
        state.isLoading = false;
        state.user = action.payload.user;
        if (action.payload.redirectUri) {
          window.location.href = action.payload.redirectUri;
        } else {
          window.location.href = '/dashboard';
        }
      } else {
        state.isLoading = false;
        state.error = 'Login Failed';
      }
    },

    refreshLoginSuccess(state, action) {
      if (action.payload.success) {
        state.isLoading = false;
        state.user = action.payload.user;
      } else {
        state.isLoading = false;
        state.error = 'Login Failed';
      }
    },

    updateUsersName(state, action) {
      state.isLoading = false;
      state.user.firstName = action.payload.firstName;
      state.user.lastName = action.payload.lastName;
    },

    logout(state) {
      state.user = initialState.user;
      window.location.reload();
    },

    updateSite(state, action) {
      if (state.user.siteId !== action.payload) {
        state.user.siteId = action.payload;
        setTimeout(() => { window.location.href = '/dashboard'; }, 300)
      }
    },   

    updateSettings(state, action) {
      state.user.settings = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
// ----------------------------------------------------------------------

export function persistLogin({ email, password, returnUrl }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await login({ email, password, returnUrl });
      if (response.success) {
        dispatch(slice.actions.loginSuccess(response));
        return response; // resolve the promise with the response
      }
      dispatch(slice.actions.hasError(response));
      return response; // resolve the promise with the response
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error; // resolve the promise with the error
    }
  };
}
export function refreshLogin() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const response = await refreshLoginData();
    dispatch(slice.actions.refreshLoginSuccess(response));
    return response;
  };
}

export function loginAsUserReq(userEmail) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await loginAsUser(userEmail);
      dispatch(slice.actions.loginSuccess(response.data));
      window.location.href = '/dashboard';
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function logout() {
  return async (dispatch) => {
    try {
      await logoutUser();
      dispatch(slice.actions.logout());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateUserSiteId(siteId) {
  return async (dispatch) => {
    try {
      await postSiteSwitch(siteId);
      dispatch(slice.actions.updateSite(siteId));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
    return false;
  };
}

export function setUserSiteIdInRedux(siteId) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.updateSite(siteId));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function updateUserInRedux(user) {
  return async (dispatch) => {
    dispatch(slice.actions.updateUsersName(user));
  };
}

export function updateSingleUser(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await updateUser(value);
      if (response) {
        dispatch(slice.actions.updateUsersName({ firstName: value.firstName, lastName: value.lastName }));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateUserSettings(userSettingsArray) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await updateUserConfig(userSettingsArray);
      if (response) {
        dispatch(slice.actions.updateSettings(response));
        return response; // Return the response here
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return null;
    }
    return null;
  };
}
