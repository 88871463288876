import axios from '../../utils/axios';
import { objectToFormData } from '../utils';

// GET
// /Api/WarehouseVendor/WarehouseVendors
export const getWarehouseVendorData = async () => {
  const response = await axios.get('/WarehouseVendor/WarehouseVendors');
  return response;
};

// GET
// /Api/WarehouseVendor/VendorLinks
export const getVendorLinksData = async () => {
  const response = await axios.get('/WarehouseVendor/VendorLinks');
  return response;
};

// GET
// /Api/WarehouseVendor/VendorLinkExists id, siteId, siteVendorId, warehouseVendorId
export const getVendorLinkExists = async (id, siteId, siteVendorId, warehouseVendorId) => {
  const response = await axios.get(`/WarehouseVendor/VendorLinkExists?id=${id}&siteId=${siteId}&siteVendorId=${siteVendorId}&warehouseVendorId=${warehouseVendorId}`);
  return response;
};

// GET
// /Api/WarehouseVendor/VendorFiles vendorid
export const getVendorFiles = async (vendorId) => {
  const response = await axios.get(`/WarehouseVendor/VendorFiles?vendorId=${vendorId}`);
  return response;
};

// GET
// /Api/WarehouseVendor/DownloadVendorFile id
export const getVendorFileDownload = async (id) => {
  const response = await axios.get(`/WarehouseVendor/DownloadVendorFile?id=${id}`, {
    responseType: 'blob',
  });
  return response;
};

// POST
// /Api/WarehouseVendor/DeleteWarehouseVendor id
export const postDeleteWarehouseVendor = async (id) => {
  const response = await axios.post(`/WarehouseVendor/DeleteWarehouseVendor?id=${id}`);
  return response;
};

// POST
// /Api/WarehouseVendor/DeleteVendorLink id
export const postDeleteVendorLink = async (id) => {
  const response = await axios.post(`/WarehouseVendor/DeleteVendorLink?id=${id}`);
  return response;
};

// POST
// /Api/WarehouseVendor/DeleteVendorFile id
export const postDeleteVendorFile = async (id) => {
  const response = await axios.post(`/WarehouseVendor/DeleteVendorFile?id=${id}`);
  return response;
};

//POST
// /Api/WarehouseVendor/VendorForm form - Id, Name, GlobalVendor
export const postVendorForm = async (form) => {
  const response = await axios.post('WarehouseVendor/VendorForm', objectToFormData(form));
  return response;
};

//POST
// /Api/WarehouseVendor/VendorLinkForm form - Id, SiteId, SiteVendorId, WarehouseVendorId
export const postVendorLinkForm = async (form) => {
  const response = await axios.post('WarehouseVendor/VendorLinkForm', objectToFormData(form));
  return response;
};

//POST
// /Api/WarehouseVendor/AddVendorFiles form - VendorId, FileTypeId
export const postAddVendorFiles = async (vendorId, fileTypeId, attachments) => {

  const formData = objectToFormData({
    vendorId,
    fileTypeId,
    attachments,
  });
  const response = await axios.post('WarehouseVendor/AddVendorFiles', formData);
  return response;
};

export const getVendorIntegrations = async () => {
  const response = await axios.get('/WarehouseVendor/VendorIntegrations');
  return response;
};

export const postVendorIntegrationForm = async (data, typeId) => {
  const formType =
        typeId === 1 ? "CXMLOrderConfigForm" :
        typeId === 2 ? "PunchoutConfigForm" : "";
  const response = await axios.post(`/WarehouseVendor/${formType}`, objectToFormData(data, null, "value"));
  return response;
};

export const deleteVendorIntegration = async (id, typeId) => {
  const response = await axios.post(`/WarehouseVendor/DeleteVendorIntegration?id=${id}&typeId=${typeId}`);
  return response;
};


export const getIntegrationsForVendor = async (id) => {
  const response = await axios.get(`/WarehouseVendor/IntegrationsForVendor?vendorId=${id}`);
  return response;
};