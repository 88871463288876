import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Grid, Box, Divider, Card, Button, Typography, Tooltip, IconButton } from '@mui/material';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { CategoryNavbar } from './order-navbar';
import { BudgetBox } from './index';
import { CATALOG } from '../../../../reusable-components/datagrid/shopColumns';
import { useSelector } from '../../../../redux/store';
import QuantityField from './QuantityField';
import { createColumns } from '../../../../reusable-components/datagrid/custom-filters';
import Iconify from '../../../../reusable-components/iconify';
import { ScopeGuard, useScopeCheck } from '../../../../reusable-components/scopes';
import Scrollbar from '../../../../reusable-components/scrollbar';
import { CollapseSidebar } from '../../../../reusable-components/collapse-sidebar';

CatalogView.propTypes = {
  data: PropTypes.array,
  setData: PropTypes.func,
};

export default function CatalogView({ data, setData }) {
  const hasBudgetViewAccess = useScopeCheck(['View-Order'], true);
  const { facilityId = '' } = useParams();
  const [sortModel, setSortModel] = useState([{ field: 'previouslyOrderedItems', sort: 'desc' }]);
  const [initialSort, setInitialSort] = useState(true);
  const {
    data: { items, cart, budgets, hasNoBudget, currFacilityId },
  } = useSelector((state) => state.orderSheet);
  const isLoading = useSelector((state) => state.orderSheet.isLoading);
  useEffect(() => {
    setData(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);
  useEffect(() => {
    if (data.length > 0 && initialSort) {
      setSortModel([{ field: 'previouslyOrderedItems', sort: 'desc' }]);
      setInitialSort(false);
    }
  }, [data.length, initialSort]);
  const customToolbar = useCallback(() => <CategoryNavbar setData={setData} setSortModel={setSortModel} />, []);

  const quantity = {
    field: 'quantity',
    headerName: 'Qty',
    flex: 0.5,
    minWidth: 80,
    renderCell: (params) => <QuantityField row={params.row} />,
    valueGetter: (params) => {
      const foundProduct = cart.find((item) => item.id === params.row.id);
      return foundProduct ? foundProduct.quantity : 0;
    },
  };

  const columns = createColumns([...CATALOG, quantity]);
  const [openSidebar, setOpenSidebar] = useState(true);

  return (
    <>
      <Grid item xl={12}>
        <Divider
          flexItem
          sx={{
            height: '2px',
            opacity: 0.39,
            backgroundColor: '  #707070',
            ml: 2,
            mb: 2,
          }}
        />
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
          <Grid item xs={hasBudgetViewAccess ? (openSidebar ? 9.3 : 11.9) : 12}>
            <Grid item xs={12}>
              <CustomDataGrid
                gridId="orders-new-order-catalog-view"
                boxSX={{ height: 'calc(100vh - 220px)' }}
                data={data}
                gridColumns={columns}
                CustomLeftToolbar={customToolbar}
                getRowId={(row) => row.id}
                disableSelectionOnClick
                sort={sortModel}
                isLoading={isLoading}
              />
            </Grid>
          </Grid>
          {hasBudgetViewAccess && (
            <Grid item xs={openSidebar ? 2.7 : 0.1} style={{ alignSelf: 'stretch', position: 'relative' }}>
              <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                <CollapseSidebar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar}>
                  {openSidebar && (
                    <>
                      <ScopeGuard scopes={['View-Order']} allowAdmin>
                        <Box>
                          <Grid item xs={12}>
                            {hasNoBudget ? (
                              <Card sx={{ p: 2, ml: 2, mt: 4 }}>No budget set. Contact your admin.</Card>
                            ) : budgets?.length > 0 ? (
                              <Grid item sx={{ mt: 2, position: 'relative' }}>
                                <Scrollbar sx={{ height: 'calc(100vh - 170px)' }}>
                                  <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{ pb: '50px' }}
                                  >
                                    <Grid item>
                                      <ScopeGuard scopes={['View-Order']} allowAdmin>
                                        {cart.length > 0
                                          ? budgets
                                              .filter((budget) =>
                                                cart.some((item) => item.agoraCategoryId === budget.agoraCategoryId)
                                              )
                                              .map((budget) => (
                                                <BudgetBox
                                                  key={budget.agoraCategoryId}
                                                  cart={cart.filter(
                                                    (item) => item.agoraCategoryId === budget.agoraCategoryId
                                                  )}
                                                  catId={budget.agoraCategoryId}
                                                  facId={facilityId}
                                                />
                                              ))
                                          : budgets.map((budget) => (
                                              <BudgetBox
                                                key={budget.agoraCategoryId}
                                                cart={[]}
                                                catId={budget.agoraCategoryId}
                                                facId={facilityId}
                                              />
                                            ))}
                                      </ScopeGuard>
                                    </Grid>
                                  </Grid>
                                </Scrollbar>
                              </Grid>
                            ) : (
                              <Card sx={{ p: 2, ml: 2, mt: 4 }}>Loading budget...</Card>
                            )}
                          </Grid>
                        </Box>
                      </ScopeGuard>
                    </>
                  )}
                </CollapseSidebar>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}
