import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Tooltip } from '@mui/material';
import { cloudinaryThumbnail, cloudinaryFull } from '../../utils/formatCloudinary';
import Image from '../image';
import Lightbox from '../lightbox';
import { fCurrency } from '../../utils/formatNumber';

const invoiceItemTotal = {
  field: 'total',
  headerName: 'Total',
  width: 100,
  type: 'customMoney',
  // renderCell: (params) => fCurrency(params.value),
  valueGetter: (params) => fCurrency(params.row.quantity * params.row.price + params.row.taxAmount),
};

export default function RenderImage({ params }) {
  // if (params.row.productDetails[0].baseUOMAmount || params.row.productDetails[0].baseUOMType) {
  // }
  RenderImage.propTypes = {
    params: PropTypes.object,
  };
  const { pictures } = params.row;
  const picture = pictures[0];
  const [selectedImage, setSelectedImage] = useState(-1);
  const handleOpenLightbox = () => {
    setSelectedImage(0);
  };
  const handleCloseLightbox = () => {
    setSelectedImage(-1);
  };
  return (
    <>
      <Image
        alt="attachment"
        src={cloudinaryThumbnail(picture)}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            opacity: 0.9,
          },
        }}
        onClick={() => handleOpenLightbox()}
      />
      <Lightbox
        index={0}
        slides={pictures?.map((picture) => ({ src: cloudinaryFull(picture) }))}
        open={selectedImage >= 0}
        close={handleCloseLightbox}
      />
    </>
  );
}

const description = {
  field: 'description',
  headerName: 'Description',
  width: 200,

  renderCell: (params) => (
    <Tooltip title={params.value} placement="bottom" arrow>
      <Box
        component="div"
        sx={{
          fontSize: '0.7rem',
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 2,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          wordBreak: 'break-all',
          whiteSpace: 'pre-wrap',
        }}
      >
        {params.value}
      </Box>
    </Tooltip>
  ),
  type: 'customText',
  valueGetter: (params) => params.row.description,
};

const vendorItemId = {
  field: 'vendorItemId',
  headerName: 'SKU',
  width: 70,
  type: 'customText',
};


const ORDER_INFORMATION = [description, vendorItemId, invoiceItemTotal];

export { ORDER_INFORMATION };
