import axios from '../../utils/axios';
import { objectToFormData } from '../utils';

// GET
// /Api/Invoices/Invoices
export async function getPendingInvoicesData() {
  const response = await axios.get('/Invoices/Invoices?pending=true');
  return response;
}

export async function getCompleteInvoicesData({ startDate, endDate, pending }) {
  const response = await axios.get(`/Invoices/Invoices?pending=${pending}&Start=${startDate}&End=${endDate}`);
  return response;
}

export async function getInvoiceStatements() {
  const response = await axios.get('/Invoices/InvoiceStatements');
  return response;
}

// GET
// /Api/Invoices/DownloadInvoiceFile
// //Download Api is used in code bec we need the `${HOST_API_URL}
export async function downloadInvoiceFile(id, mainFile) {
  const response = await axios.get(`/Invoices/DownloadInvoiceFile?id=${id}&mainFile=${mainFile}`, {
    responseType: 'blob',
  });
  return response;
}

//POST
//
export async function markInvoiceAsSent(id) {
  const response = await axios.post(`/Invoices/MarkInvoiceAsSent?id=${id}&sent=true`);
  return response;
}

// POST
// /Api/Invoices/AddInvoiceFile id file
export async function postAddInvoiceFile(id, file) {
  const formData = objectToFormData({
    id,
    file,
  });
  const response = await axios.post('/Invoices/AddInvoiceFile', formData);
  return response;
}

//POST
// /Api/Invoices/DeleteInvoiceFile id
export async function deleteInvoiceFile(id) {
  const response = await axios.post(`/Invoices/DeleteInvoiceFile?id=${id}`);
  return response;
}

// /Api/Invoices/DeleteInvoice id
export async function deleteInvoice(id) {
  const response = await axios.post(`/Invoices/DeleteInvoice?id=${id}`);
  return response;
}

// POST
// /Api/Invoices/SetInvoiceFileAsMain id
export async function setInvoiceFileAsMain(id) {
  const response = await axios.post(`/Invoices/SetInvoiceFileAsMain?id=${id}`);
  return response;
}

// POST
// /Api/Invoices/RecreateInvoice id
export async function recreateInvoice(id) {
  const response = await axios.post(`/Invoices/RecreateInvoice?id=${id}`);
  return response;
}

// GET
// /Api/Invoices/InvoiceFiles
export async function getInvoiceItemsFiles(id) {
  const response = await axios.get(`/Invoices/InvoiceFiles?id=${id}`);
  return response;
}

// GET/Api/Invoices/InvoiceFormData;
export async function getInvoiceFormData() {
  const response = await axios.get('/Invoices/InvoiceFormData');
  return response;
}

// GET
// /Api/Invoices/InvoiceGlItems invoiceId
export async function getInvoiceGlItems(invoiceId) {
  const response = await axios.get(`/Invoices/InvoiceGlItems?invoiceId=${invoiceId}`);
  return response;
}

// GET
// /Api/Invoices/Invoice id
export async function getSingleInvoice(id) {
  const response = await axios.get(`/Invoices/Invoice?id=${id}`);
  return response;
}

// POST/Api/Invoices/UpdateInvoiceItems; invoiceId items
//objectToFormData
export async function updateInvoiceItems(invoiceId, data) {
  const items = data?.map((item) => {
    const {
      id,
      description,
      uomType,
      vendorItemId,
      expectedItemPrice,
      price,
      quantity,
      taxAmount,
      expectedAmount,
      categoryId,
      subcategoryId,
      glCode,
    } = item;
    return {
      id,
      description,
      uomType,
      vendorItemId,
      expectedItemPrice,
      price,
      quantity,
      taxAmount,
      agoraCategoryId: categoryId,
      agoraSubcategoryId: subcategoryId,
      glCode,
      expectedAmount,
    };
  });

  const formData = objectToFormData({
    InvoiceId: invoiceId,
    Items: items,
  });
  const response = await axios.post('/Invoices/UpdateInvoiceItems', formData);
  return response;
}

// POST/Api/Invoices/BookInvoice; InvoiceAttachId BillNumber DueDate TransactionDate GlPostingDate Lines [{"transactionAmount": 0,"glCode": "string","memo": "string"}]
export async function bookInvoice(invoice, invoiceItemRows) {
  const formData = objectToFormData({
    InvoiceAttachId: invoice?.id,
    BillNumber: invoice?.vendorInvoiceId,
    TransactionDate: invoice?.transactionDate ? invoice?.transactionDate : invoice?.date,
    glPostingDate: invoice?.glPostingDate,
    dueDate: invoice?.dueDate,
    description: invoice?.description,
    referenceNumber: invoice?.referenceNumber,

    Lines: invoiceItemRows.map((item) => ({
      transactionAmount: item.transactionTotal,
      glCode: item.agoraSubcategory.glCode,
      memo: item.memo ? item.memo : '',
    })),
  });

  const response = await axios.post('/Invoices/BookInvoice', formData);
  return response;
}
// POST/Api/Invoices/BookInvoice; InvoiceAttachId BillNumber DueDate TransactionDate GlPostingDate Lines [{"transactionAmount": 0,"glCode": "string","memo": "string"}]
export async function bookInvoice2(invoice, invoiceItemRows) {
  const formData = objectToFormData({
    InvoiceAttachId: invoice?.id,
    BillNumber: invoice?.vendorInvoiceId,
    TransactionDate: invoice?.transactionDate ? invoice?.transactionDate : invoice?.date,
    glPostingDate: invoice?.glPostingDate,
    dueDate: invoice?.dueDate,
    description: invoice?.description,
    referenceNumber: invoice?.referenceNumber,

    Lines: invoiceItemRows.map((item) => ({
      facilityId: item.facilityId,
      transactionAmount: Math.round((Number(item.amount) + Number(item.shipping) + Number.EPSILON) * 100) / 100,
      glCode: item.glCode,
      memo: item.memo ? item.memo : '',
    })),
  });

  const response = await axios.post('/Invoices/BookInvoice', formData);
  return response;
}

// POST/Api/Invoices/DeleteInvoiceStatement;
export async function deleteInvoiceStatement(id) {
  const response = await axios.post(`/Invoices/DeleteInvoiceStatement?id=${id}`);
  return response;
}

// POST/Api/Invoices/SplitPdf; splitOn[2,4] file
export async function splitPdf(splitOn, file) {
  const formData = objectToFormData({
    splitOn,
    file,
  });
  const response = await axios.post('/Invoices/SplitPdf', formData, {
    responseType: 'blob',
  });
  return response;
}

// POST/Api/Invoices/ManualInvoiceUpload; files
export async function uploadManualInvoices(files) {
  const formData = objectToFormData({
    files,
  });
  const response = await axios.post('/Invoices/ManualInvoiceUpload', formData);
  return response;
}

// GET/Api/Invoices/ManualInvoices
export async function getManualInvoices() {
  const response = await axios.get('/Invoices/ManualInvoices');
  return response;
}
// POST/Api/Invoices/UploadInvoicePdf; vendorId file
export async function uploadInvoicePdf(vendorId, file) {
  const formData = objectToFormData({
    vendorId,
    file,
  });
  const response = await axios.post('/Invoices/UploadInvoicePdf', formData);
  return response;
}

// POST/Api/Invoices/UploadStatement;
export async function uploadStatement(vendorId, file) {
  const formData = objectToFormData({
    vendorId,
    file,
  });
  const response = await axios.post('/Invoices/UploadStatement', formData);
  return response;
}

// POST/Api/Invoices/GenerateInvoicePdfFromData; id
export async function generateInvoicePdfFromData(id) {
  const response = await axios.post(`/Invoices/GenerateInvoicePdfFromData?id=${id}`);
  return response;
}

// GET/Api/Invoices/DownloadInvoicesExcel;
// //Download Api is used in code bec we need the `${HOST_API_URL}

// GET/Api/Invoices/DownloadInvoiceStatements;
// //Download Api is used in code bec we need the `${HOST_API_URL}

// GET/Api/Invoices/InvoiceStatementLines; id
export async function getInvoiceStatementLines(id) {
  const response = await axios.get(`/Invoices/InvoiceStatementLines?id=${id}`);
  return response;
}

// GET
// /Api/Invoices/Vendors
export async function getVendorsData() {
  const response = await axios.get('/Invoices/Vendors');
  return response;
}
// POST
// /Api/Invoices/VendorForm
export async function postVendorForm(form) {
  const updatedForm = {
    Name: form.name,
    EmailTo: form.emailTo,
    EmailToCC: form.emailToCC,
    Address: form.address,
    Zip: form.zip,
    City: form.city,
    State: form.state,
    Phone: form.phone,
    Logo: form.logo,
    HasInvoices: form.hasInvoices,
  };

  const formData = objectToFormData({
    ...updatedForm,
  });

  const response = await axios.post('Invoices/VendorForm', formData);
  return response;
}
// GET/Api/Invoices/DownloadInvoiceStatementFile;
// //Download Api is used in code bec we need the `${HOST_API_URL}

// GET/Api/Invoices/ViewStatementFile;
// //View Api is used in code bec we need the `${HOST_API_URL}

// GET/Api/Invoices/ViewInvoiceFile;
// //View Api is used in code bec we need the `${HOST_API_URL}
export async function viewInvoiceFile(id, mainFile) {
  const response = await axios.get(`/Invoices/ViewInvoiceFile?id=${id}&mainFile=${mainFile}`, {
    responseType: 'blob',
  });
  return response;
}

// POST/Api/Invoices/InvoiceForm; invoice, facilityId, v
//  Id FacilityId VendorId InvoiceId VendorInvoiceId ExpectedPrice Shipping Total AlreadySent Date
export async function postInvoiceForm(invoice) {
  const updatedData = {
    FacilityId: invoice.facilityId,
    Id: invoice.id,
    VendorId: invoice.vendorId,
    InvoiceId: invoice.invoiceId,
    VendorInvoiceId: invoice.vendorInvoiceId,
    ExpectedPrice: invoice.expectedPrice,
    Shipping: invoice.shipping,
    Total: invoice.total,
    AlreadySent: invoice.sent,
    Date: invoice.date,
    DueDate: invoice.dueDate,
    GlDate: invoice.glPostingDate,
    ReferenceNumber: invoice.referenceNumber,
  };

  const formData = objectToFormData(updatedData);
  const response = await axios.post('/Invoices/InvoiceForm', formData);
  return response;
}
// POST/Api/Invoices/InvoiceForm2
export async function postInvoiceForm2(invoice) {
  const updatedData = {
    Id: invoice.id,
    VendorId: invoice.vendorId,
    FacilityId: invoice.facilityId,
    InvoiceId: invoice.invoiceId,
    VendorInvoiceId: invoice.vendorInvoiceId,
    ExpectedPrice: invoice.expectedPrice,
    Shipping: invoice.shipping,
    Quantity: invoice.quantity,
    Total: invoice.total,
    InvoiceType: invoice.total >= 0 ? 'Invoice' : 'CreditMemo',
    MultiFacility: invoice.multiFacility,
    ReferenceNumber: invoice.referenceNumber,
    AlreadySent: invoice.sent,
    Date: invoice.date,
    DueDate: invoice.dueDate,
    GlPostingDate: invoice.glPostingDate,
    SourceMethod: invoice.sourceMethod,
  };

  const formData = objectToFormData(updatedData);
  const response = await axios.post('/Invoices/InvoiceForm2', formData);
  return response;
}
// POST/Api/Invoices/UpdateInvoiceField
export async function updateInvoiceField(invoiceId, field) {
  const formData = new FormData();
  formData.append('id', invoiceId);
  formData.append('key', field.key);
  formData.append('value', field.value);

  const response = await axios.post('/Invoices/UpdateInvoiceField', formData);
  return response;
}

// POST/Api/Invoices/InvoiceGLItemsForm
export async function postInvoiceGLItemsForm(invoiceGlItems) {
  const formData = new FormData();
  invoiceGlItems.forEach((item, index) => {
    Object.entries(item).forEach(([key, value]) => {
      formData.append(`${'input'}[${index}].${key}`, value !== null && value !== '' ? value : '');
    });
  });

  const response = await axios.post('/Invoices/InvoiceGLItemsForm', formData);
  return response;
}

// POST/Api/Invoices/RemoveGlItems
export async function removeGlItems(invoiceId) {
  const response = await axios.post(`/Invoices/RemoveGlItems?invoiceId=${invoiceId}`);
  return response;
}
// POST/Api/Invoices/FacilityModeToggle
export async function facilityModeToggle(invoiceId, mode) {
  const formData = objectToFormData({
    InvoiceId: invoiceId,
    Mode: mode,
  });
  const response = await axios.post('/Invoices/FacilityModeToggle', formData);
  return response;
}
export async function getInvoiceStatus(id) {
  const response = await axios.post(`/Invoices/InvoiceStatus?id=${id}`);
  return response;
}

// POST/APi/Invoices/AddInvoiceLogMessage
export async function addInvoiceLogMessage(
  invoiceId,
  messageType,
  extraInfo,
  mentions = null,
  parentCommentId,
  invoiceUrl
) {
  const formData = objectToFormData({
    invoiceId,
    messageType,
    extraInfo,
    mentions,
    parentCommentId,
    invoiceUrl,
  });
  const response = await axios.post('/Invoices/AddInvoiceLogMessage', formData);
  return response;
}

// POST
//APi/Invoices/DeleteInvoiceLogMessage
export async function deleteInvoiceLogMessage(logId) {
  const response = await axios.post(`/Invoices/DeleteInvoiceLogMessage?logId=${logId}`);
  return response;
}

// GET
// /Api/Invoices/InvoiceLog
export async function getInvoiceLogData(invoiceId) {
  const response = await axios.get(`/Invoices/InvoiceLog?invoiceId=${invoiceId}`);
  return response;
}
// GET
// /Api/Invoices/InvoiceBreakdownData
export async function getInvoiceBreakdownData(id) {
  const response = await axios.get(`/Invoices/InvoiceBreakdownData?id=${id}`);
  return response;
}

// GET
// /Api/Invoices/InvoiceViewers
export async function getInvoiceViewersList(invoiceId) {
  const response = await axios.get(`/Invoices/InvoiceViewers?invoiceId=${invoiceId}`);
  return response;
}

// GET
// /Api/Invoices/GlConsolidationFacilities
export async function getGlConsolidationFacilities() {
  const response = await axios.get(`/Invoices/GlConsolidationFacilities`);
  return response;
}
// POST
// /Api/Invoices/UpdateFacilitiesGlConsolidation
export async function updateFacilitiesGlConsolidation(facilities) {
  const formData = objectToFormData({
    Facilities: facilities,
  });
  const response = await axios.post(`/Invoices/UpdateFacilitiesGlConsolidation`, formData);
  return response;
}
