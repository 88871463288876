import { Helmet } from 'react-helmet-async';
import { Grid } from '@mui/material';
// components

// ----------------------------------------------------------------------

export default function RentalCatalog() {
  return (
    <>
      <Helmet>
        <title>Rentals Catalog | Platform Solutions</title>
      </Helmet>

      <Grid item xl={12}>
        RentalCatalog
      </Grid>
    </>
  );
}
