import { React, useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Box, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from '../../../../redux/store';
import { patchInvoiceItems, getManualInvoicing } from '../../../../redux/slices/invoicing';
import { AwaitButton } from '../../../../reusable-components/await-button';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import Iconify from '../../../../reusable-components/iconify';
import { bookInvoice2 } from '../../../../api/invoicing';
import { useScopeCheck } from '../../../../reusable-components/scopes';
import { NotificationDialog } from '../../../../reusable-components/dialog';

SubmitInvoice.propTypes = {
  glRows: PropTypes.array,
  setEnableMarkAsSent: PropTypes.func,
  unsavedData: PropTypes.object,
  setUnsavedData: PropTypes.func,
};
export default function SubmitInvoice({ glRows, setEnableMarkAsSent, unsavedData, setUnsavedData }) {
  const { id } = useParams();
  const {
    data: { manualInvoicing },
  } = useSelector((state) => state.invoicing);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const hasSubmitAccess = useScopeCheck(['Submit-Invoice'], false, true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitComplete, setSubmitComplete] = useState(false);
  const [missingPermission, setMissingPermission] = useState(false);

  const {
    data: { invoiceItemData },
  } = useSelector((state) => state.invoicing);
  const { bookingType } = invoiceItemData || '';
  const { invoice } = invoiceItemData || {};
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (submitComplete) {
      if (invoice?.sourceMethod === 'Manual' && manualInvoicing?.length > 0) {
        const nextId = manualInvoicing?.[0]?.id;
        navigate(`/dashboard/invoicing/new/${nextId}`);
      } else {
        const pathSegments = location.pathname.split('/');
        const url = pathSegments.slice(0, -1).join('/');
        navigate(url);
      }
    }
  }, [manualInvoicing, submitComplete]);
  const submit = async () => {
    if (!hasSubmitAccess) {
      setMissingPermission(true);
      setIsSubmitting(false);
      return;
    }

    setSubmitComplete(false);
    const response = await bookInvoice2(invoice, glRows);

    if (response.status === 200) {
      if (response.data.keepFormOpen) {
        enqueueSnackbar(response.data.message, {
          variant: response.data.success ? 'success' : 'error',
          persist: true,
        });
        if (response.data.message?.startsWith(`Invoice '${invoice?.vendorInvoiceId}' already in Intacct.`)) {
          setEnableMarkAsSent(true);
        }
      } else {
        enqueueSnackbar(response.data.message, { variant: response.data.success ? 'success' : 'error' });
      }
    } else {
      enqueueSnackbar('Unable to submit invoice', { variant: 'error' });
    }
    if (response.data.success) {
      if (invoice?.sourceMethod === 'Manual') await dispatch(getManualInvoicing());
      setSubmitComplete(true);
    }
    setIsSubmitting(false);
  };
  useEffect(() => {
    if (Object.values(unsavedData)?.length === 0 && isSubmitting) {
      submit();
    }
  }, [unsavedData, isSubmitting]);
  const save = async () => {
    if (Object.values(unsavedData)?.length > 0) {
      await dispatch(patchInvoiceItems(invoice.id, Object.values(unsavedData)));
      setUnsavedData({});
    }
    setIsSubmitting(true);
  };
  return (
    <>
      <Tooltip
        title={
          invoice?.glPostingDate === null
            ? 'Gl posting date is required'
            : glRows?.length <= 0
            ? 'Missing GLs'
            : bookingType !== 'Intacct' && invoice?.multiFacility
            ? 'Invoice booking type must be  Intacct'
            : ''
        }
        placement="top"
        arrow
      >
        <Box>
          <AwaitButton
            variant="contained"
            color="secondary"
            onClick={save}
            loading={isSubmitting}
            loadingPosition="start"
            disabled={
              invoice?.glPostingDate === null ||
              glRows?.length === 0 ||
              (bookingType !== 'Intacct' && invoice?.multiFacility)
            }
            startIcon={<Iconify icon="fa:paper-plane-o" />}
          >
            Submit Invoice
          </AwaitButton>
        </Box>
      </Tooltip>
      <NotificationDialog
        open={missingPermission}
        setOpen={setMissingPermission}
        title="  Permission missing"
        content={"You don't have permission to submit the invoice."}
      />
    </>
  );
}
