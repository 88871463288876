import { useCallback, useState, useEffect, useMemo } from 'react';
import { Autocomplete, Button, Grid, TextField } from '@mui/material';
import { useDispatch, useSelector } from '../../../../../redux/store';
import { ROLES_COLUMNS } from '../../../../../reusable-components/datagrid/permissionColumns';
import CustomDataGrid from '../../../../../reusable-components/datagrid/CustomDataGrid';
import {
  userRoleData,
  updateUsersRoleValuesInRedux,
  updateUsersRolesInRedux,
  updateTemplateIdInRedux,
} from '../../../../../redux/slices/users';
import RolesCheckbox from './RolesCheckbox';
import RolesModal from './RolesModal';
import { getUserTemplateValuesData, updateUserRoles } from '../../../../../api/user';
import { useScopeCheck } from '../../../../../reusable-components/scopes';
import { AwaitButton } from '../../../../../reusable-components/await-button';
import { useSnackbar } from '../../../../../reusable-components/snackbar';

export default function RolesTab() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: { userRoleTypeList, user, userRoleTemplates, unsavedChanges },
  } = useSelector((state) => state.users);

  const isLoading = useSelector((state) => state.users.isLoading);

  const { roles, roleValues, templateId } = user;

  const hasEditAccess = useScopeCheck(['User-Edit'], true);
  const [sortModel, setSortModel] = useState([{ field: 'active', sort: 'asc' }]);
  const [initialSort, setInitialSort] = useState(true);

  useEffect(() => {
    if (roles.length > 0 && initialSort) {
      setSortModel([{ field: 'active', sort: 'asc' }]);
      setInitialSort(false);
    }
  }, [roles, initialSort]);
  //user should be checked/unchecked automatically when invoiceNew /InvoiceEdit is changed
  useEffect(() => {
    const userNotInOptions =
      roleValues?.find((x) => x.userPermissionTypeId === 47 && x.modelId === user.alternateId) === undefined;
    if (roles.includes('New-Invoice') && roles.includes('Invoice-Edit') && userNotInOptions) {
      const newRole = {
        userPermissionTypeId: 47,
        modelId: user.alternateId,
        modelAttachmentId: undefined,
      };
      dispatch(updateUsersRoleValuesInRedux([...roleValues, newRole]));
    } else if (
      ((roles.includes('New-Invoice') && !roles.includes('Invoice-Edit')) ||
        (!roles.includes('New-Invoice') && roles.includes('Invoice-Edit'))) &&
      !userNotInOptions
    ) {
      const removeRole = roleValues.filter((role) => role.modelId !== user.alternateId);
      dispatch(updateUsersRoleValuesInRedux(removeRole));
    }
  }, [roles]);

  const active = {
    field: 'active',
    headerName: 'Active',
    flex: 0.5,
    renderCell: (params) => <RolesCheckbox role={params.row.role} />,
    valueGetter: (params) => (roles.includes(params.row.role) ? `0${params.row.label}` : `1${params.row.label}`),
  };

  const actions = {
    field: 'actions',
    headerName: 'Permissions',
    flex: 0.5,
    renderCell: (params) => <RolesModal params={params.row} />,
  };

  const handleSave = async () => {
    const response = await updateUserRoles({ id: user.id, templateId, roles, roleValues });
    if (response) {
      enqueueSnackbar('Users roles updated', {
        variant: 'success',
      });

      dispatch(userRoleData(user.id, 1));
    }
  };

  const handleTemplateChange = async (id) => {
    dispatch(updateTemplateIdInRedux(id));
    try {
      const response = await getUserTemplateValuesData(id);
      if (response.data) {
        const { roles, values, useUserFacility } = response.data;
        const allRoles = roles.map((role) => {
          const roleType = userRoleTypeList.find((roleType) => roleType.id === role.roleId);
          return roleType?.role;
        }).filter(role => role);
        const allRolesValues = values.map((role) => ({
          userPermissionTypeId: role.roleId,
          modelId: role.modelId,
          modelAttachmentId: role.modelAttachmentId,
        }));
        dispatch(updateUsersRolesInRedux(allRoles));
        dispatch(updateUsersRoleValuesInRedux(allRolesValues, useUserFacility, roles, userRoleTypeList, user));
      }
      setSortModel([{ field: 'description', sort: 'desc' }]);
      // setInitialSort(true);
    } catch (error) {
      console.error(error);
    }
  };
  const roleValue = useMemo(() => userRoleTemplates.find((option) => option.id === templateId), [templateId]);

  // console.log('userRoleTemplates', roleValue.useUserFacility);

  const customToolbar = useCallback(
    () => (
      <>
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end" sx={{ mt: 1, mb: 0.5 }}>
          <Autocomplete
            fullWidth
            id="combo-box-demo"
            options={userRoleTemplates}
            getOptionLabel={(option) => option?.name || ''}
            size="small"
            value={roleValue}
            onChange={(event, newValue) => {
              if (newValue) {
                handleTemplateChange(newValue?.id);
              }
            }}
            disabled={!hasEditAccess}
            renderInput={(params) => <TextField {...params} label="Template Role" />}
            sx={{
              width: '25vw',
              mr: 1,
            }}
          />
        </Grid>
      </>
    ),
    [hasEditAccess, roleValue, userRoleTemplates]
    // [userRoleTemplates, roleValue, unsavedChanges]
  );

  return (
    <Grid item>
      <>
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end" sx={{ mb: 1, mt: 0.5 }}>
          <Grid item>
            {unsavedChanges && (
              <>
                <Button
                  variant="outlined"
                  color="error"
                  size="small"
                  sx={{ width: 150, mr: 1 }}
                  onClick={() => {
                    dispatch(userRoleData(user.id, 1));
                  }}
                >
                  Cancel
                </Button>
                <AwaitButton
                  variant="contained"
                  color="primary"
                  size="small"
                  sx={{ width: 150 }}
                  onClick={handleSave}
                  loading={isLoading}
                >
                  Save
                </AwaitButton>
              </>
            )}
          </Grid>
        </Grid>
      </>
      <CustomDataGrid
        gridId="admin-user-roles-tab"
        boxSX={{
          width: '80vw',
          height: 'calc(100vh - 225px)',
        }}
        // scrollbarHeight="80vh"
        data={userRoleTypeList}
        gridColumns={[ROLES_COLUMNS, active, hasEditAccess && actions].flat()}
        CustomLeftToolbar={customToolbar}
        sort={sortModel}
        isLoading={isLoading}
      />
    </Grid>
  );
}
