import { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import {
  DataGridPro,
  useGridApiRef,
  gridFilteredSortedRowIdsSelector,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid-pro';
import { useTabs } from '../../context/TabContext';
import { useSelector } from '../../redux/store';
import { createColumns, CustomFilter, CustomQuickFilter } from './custom-filters';
import { CustomSettings } from './grid-settings';
import { useFilterModel } from '../../hooks/useFilterModel';
import { SkeletonDataGrid } from '../skeleton/SkeletonDataGrid';
import Scrollbar from '../scrollbar';

CustomDataGrid.propTypes = {
  data: PropTypes.array,
  gridColumns: PropTypes.array,
  id: PropTypes.string,
  gridId: PropTypes.string,
  presetFilter: PropTypes.string,
  enableRowClick: PropTypes.bool,
  CustomLeftToolbar: PropTypes.any,
  maxValue: PropTypes.object,
  boxSX: PropTypes.object,
  sx: PropTypes.object,
  onRowClick: PropTypes.func,
  onCellClick: PropTypes.func,
  isModal: PropTypes.bool,
  applyQuickFilter: PropTypes.bool,
  disabled: PropTypes.bool,
  dontGetRowHeight: PropTypes.bool,
  forceReRender: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]), // array works bec technically data in the array change thats forcing a re-render useful for checkboxes
  hideToolbar: PropTypes.bool,
  hideFilterSettings: PropTypes.bool,
  scrollbarHeight: PropTypes.number,
  setActiveRows: PropTypes.func,
  sort: PropTypes.array,
  pinnedColumns: PropTypes.object,
  displayCheckboxRight: PropTypes.bool,
  isLoading: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),
  apiRef: PropTypes.object,
  advancedFilter: PropTypes.bool,
  advancedSearchBase: PropTypes.string,
  tableBoxSX: PropTypes.object,
};
export default function CustomDataGrid({
  data,
  gridColumns,
  id = 'id',
  gridId = 'id',
  enableRowClick = false,
  applyQuickFilter = false,
  CustomLeftToolbar = () => <></>,
  maxValue,
  boxSX = { width: '100%', height: 'calc(100vh - 240px)' },
  tableBoxSX = { height: 'calc(100% - 25px)' },
  sx = {},
  onRowClick = () => {},
  onCellClick = () => {},
  isModal = false,
  setActiveRows,
  sort = [],
  pinnedColumns = {},
  displayCheckboxRight = false,
  disabled = false,
  isLoading = null,
  dontGetRowHeight = false,
  scrollbarHeight = 100,
  hideToolbar = false,
  hideFilterSettings = false,
  apiRef,
  presetFilter,
  forceReRender,
  advancedFilter = false,
  advancedSearchBase,
  ...dataGridProps
}) {
  const { activeTab, addNewTabAndNavigate } = useTabs();

  const gridRef = useGridApiRef();
  const baseUrl = window.location.href.includes('&start')
    ? window.location.href.split('&start')[0]
    : window.location.href;
  const sessionStorageKey = `tab-${activeTab.key}-filters-${baseUrl}-${gridId}`;
  const dataGridStateStorage = `dataGridState-${gridId}`;
  const sortStorageKey = `tab-${activeTab.key}-sort-${baseUrl}-${gridId}`;
  const { user } = useSelector((state) => state.user);
  const { settings } = user;

  const gridPersists = settings?.find((setting) => setting?.areaId === 'GridPersists')?.show ?? true;

  const updateSortStorage = (newFilters) => {
    sessionStorage.setItem(sortStorageKey, JSON.stringify(newFilters));
  };

  const initialState = useMemo(
    () => JSON.parse(localStorage.getItem(dataGridStateStorage)) || {},
    [dataGridStateStorage]
  );

  const loadFiltersFromSession = () => {
    const savedFilters = sessionStorage.getItem(sessionStorageKey);
    return savedFilters && !isModal ? JSON.parse(savedFilters) : {};
  };

  const loadSortFromSession = () => {
    const savedSort = sessionStorage.getItem(sortStorageKey);
    return savedSort && !isModal ? JSON.parse(savedSort) : sort || [];
  };
  const [filters, setFilters] = useState(loadFiltersFromSession());
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeFilter, setActiveFilter] = useState(null);
  const [quickFilter, setQuickFilter] = useState('');
  const [sortModel, setSortModel] = useState(loadSortFromSession());
  const [columnsState, setColumnsState] = useState();

  useEffect(() => {
    if (!sort.length) return;
    setSortModel(loadSortFromSession());
  }, [activeTab.key]);

  useEffect(() => {
    const currentState = apiRef?.current?.exportState() || gridRef?.current?.exportState();
    setColumnsState(currentState);
  }, [apiRef, gridRef]);

  const columnReorder = (colState) => {
    const updatedColumns = colState?.columns
      ? gridColumns
          .map((col) => ({
            ...col,
            ...colState?.columns?.dimensions[col.field],
          }))
          .sort((a, b) => {
            const indexA = colState?.columns?.orderedFields?.indexOf(a.field);
            const indexB = colState?.columns?.orderedFields?.indexOf(b.field);
            if (indexA > -1 && indexB > -1) {
              return indexA - indexB;
            }
            return 0;
          })
      : gridColumns;

    return updatedColumns;
  };

  const [filteredRows, setFilteredRows] = useState(data); //to be used for getting subcategory select options to match against the filtered categories

  const [pinnedCols, setPinnedCols] = useState(pinnedColumns);

  function getValueByPath(obj, path) {
    return path.split('.').reduce((o, p) => (o || {})[p], obj);
  }
  const fieldValues = useMemo(() => {
    const values = {};

    gridColumns?.forEach((col) => {
      const isSubcategory =
        col.field?.toLowerCase().includes('subcategory') || col.headerName?.toLowerCase().includes('subCategory');

      if (col?.type === 'checkboxSelection') return;
      if (col?.type === 'customSelect' && data) {
        // const dataToUse = isSubcategory ? filteredRows : data;
        const dataToUse = isSubcategory ? data : data;
        //subcategory glitch fix if you want to filter by category I need more data from the api

        if (col.path) {
          values[col.field] = Array.from(new Set(dataToUse.map((row) => getValueByPath(row, col.path)))).map(
            (value) => ({
              value,
            })
          );
        } else
          values[col.field] = Array.from(new Set(dataToUse.map((row) => row[col.field])))
            .filter((value) => value !== undefined && value !== null && value !== '')
            .map((value) => ({ value }));
      }
    });

    return values;
  }, [data, filteredRows, gridColumns]);

  useEffect(() => {
    setFilters(loadFiltersFromSession());
  }, [activeTab]);

  const columns = useMemo(
    () => createColumns(columnReorder(columnsState), filters, setAnchorEl, setActiveFilter, gridId, activeFilter),
    [activeFilter, filters, gridId, columnsState, forceReRender]
  );

  const filterModel = useFilterModel(filters);

  const filterRows = (rows, quickFilter) => {
    if (!quickFilter) return rows;

    const normalizeString = (str) => str?.replace(/[^a-z0-9\s]/gi, '')?.toLowerCase();

    const quickFilterWords = normalizeString(quickFilter).split(/\s+/);

    if (!quickFilterWords.length) return rows;

    return rows.filter((row) =>
      quickFilterWords.every((word) =>
        columns.some((col) => {
          const value = col?.valueGetter ? col.valueGetter({ row }) : row[col.field];
          if (Array.isArray(value)) {
            return value.some((element) => normalizeString(String(element))?.includes(word));
          }
          return normalizeString(String(value))?.includes(word);
        })
      )
    );
  };

  useEffect(() => {
    const visibleRowIds = gridFilteredSortedRowIdsSelector(apiRef?.current?.state || gridRef?.current?.state);

    const visibleRows = visibleRowIds?.map((rowId) => {
      const rowParams = apiRef?.current?.getRow(rowId) || gridRef?.current?.getRow(rowId);

      return rowParams;
    });
    setFilteredRows(visibleRows);
    if (setActiveRows) {
      setActiveRows(visibleRows);
    }
  }, [quickFilter, filterModel, data, gridRef, apiRef]);

  const handleGridWidthChange = () => {
    const currentState = apiRef?.current?.exportState() || gridRef?.current?.exportState();
    if (gridPersists) {
      localStorage.setItem(dataGridStateStorage, JSON.stringify(currentState));
    }
    setColumnsState(currentState);
  };
  const handleGridOrderChange = () => {
    const currentState = apiRef?.current?.exportState() || gridRef?.current?.exportState();
    if (gridPersists) {
      localStorage.setItem(dataGridStateStorage, JSON.stringify(currentState));
    }
    setColumnsState(currentState);
  };
  const handleGridVisibilityChange = () => {
    const currentState = apiRef?.current?.exportState() || gridRef?.current?.exportState();
    if (gridPersists) {
      localStorage.setItem(dataGridStateStorage, JSON.stringify(currentState));
    }
    setColumnsState(currentState);
  };

  const resetGridState = () => {
    localStorage.setItem(dataGridStateStorage, JSON.stringify({}));
    setColumnsState({});
    setFilters({});
    setSortModel(sort);
    sessionStorage.removeItem(sessionStorageKey);
    sessionStorage.removeItem(sortStorageKey);
    setQuickFilter('');
    window.location.reload();
  };

  if (!initialState) return null;
  return (
    <Scrollbar
      onClickCapture={(e) => {
        if (disabled) e.stopPropagation();
      }}
      sx={{
        height: scrollbarHeight ? `max(${scrollbarHeight}vh - 170px, 180px)` : 'auto',
        opacity: disabled ? 0.7 : 1,
      }}
    >
      <Grid item xs={12}>
        <Box sx={boxSX}>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
            {hideToolbar ? (
              <Grid item />
            ) : (
              <Grid item xs={8}>
                <CustomLeftToolbar />
              </Grid>
            )}

            {!hideFilterSettings && (
              <Grid item xs={4}>
                <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
                  <Box sx={{ width: 'calc(100% - 40px)' }}>
                    <CustomQuickFilter
                      setActiveFilter={setActiveFilter}
                      setQuickFilter={setQuickFilter}
                      presetFilter={presetFilter}
                      setFilters={setFilters}
                      isModal={isModal}
                      applyQuickFilter={applyQuickFilter}
                      gridId={gridId}
                      advancedFilter={advancedFilter}
                      advancedSearchBase={advancedSearchBase}
                    />
                  </Box>
                  <CustomSettings
                    columnsState={columnsState}
                    setColumnsState={setColumnsState}
                    gridColumns={gridColumns}
                    resetGridState={resetGridState}
                    dataGridStateStorage={dataGridStateStorage}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          <Box sx={tableBoxSX}>
            <DataGridPro
              initialState={{
                //nitial state comes from localStorage that is set in handleGridStateChange we spread it here to override the default inital values + a bit of logic to reset the columnVisibilityModel if all columns are hidden
                ...initialState,
                preferencePanel: { open: false },
                columns: {
                  ...initialState.columns,
                  columnVisibilityModel:
                    Object.keys(initialState?.columns?.columnVisibilityModel || {})?.length === gridColumns.length
                      ? {}
                      : initialState.columns?.columnVisibilityModel,
                },
                sorting: {
                  // ...initialState.sorting,
                  sortModel: sortModel || [],
                },
                // orderedFields: gridColumns.map((col) => col.field),
              }}
              getRowHeight={() => (dontGetRowHeight ? '' : 'auto')}
              rows={filterRows(data, quickFilter, columns)}
              getRowId={(row) => row[id]}
              columns={
                displayCheckboxRight
                  ? [
                      ...columns,
                      {
                        ...GRID_CHECKBOX_SELECTION_COL_DEF,
                        width: 50
                      },
                    ]
                  : columns
              }
              //dont change components to slots it breaks the skeleton loading overlay
              components={{ LoadingOverlay: SkeletonDataGrid }}
              filterModel={filterModel}
              disableRowSelectionOnClick={!enableRowClick}
              loading={isLoading !== null ? isLoading : !data.length}
              onRowClick={onRowClick}
              onCellClick={onCellClick}
              pinnedColumns={pinnedCols}
              onPinnedColumnsChange={(params) => {
                setPinnedCols(params.pinnedColumns);
              }}
              onColumnWidthChange={handleGridWidthChange}
              onColumnOrderChange={handleGridOrderChange}
              onColumnVisibilityModelChange={handleGridVisibilityChange}
              sx={{
                ...sx,
                minHeight: 130,
                mt: 1,
                '& .MuiDataGrid-row:hover': {
                  cursor: enableRowClick ? 'pointer' : 'default',
                },
                '& .disabled': {
                  pointerEvents: 'none', // Prevents hover and click
                  color: 'rgba(0, 0, 0,0.65)', // Text color for disabled state
                  backgroundColor: 'rgba(0, 0, 0, 0.12)', // Background for disabled state
                },
              }}
              disableColumnMenu
              disableColumnFilter
              // disableColumnSelector
              apiRef={apiRef || gridRef}
              sortModel={sortModel}
              onSortModelChange={(model) => {
                setSortModel(model);
                updateSortStorage(model);
              }}
              {...dataGridProps}
            />
          </Box>
        </Box>

        <CustomFilter
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          setActiveFilter={setActiveFilter}
          activeFilter={activeFilter}
          filters={filters}
          setFilters={setFilters}
          fieldValues={fieldValues}
          columns={columns}
          maxValue={maxValue}
          filteredRows={filteredRows}
          gridId={gridId}
        />
      </Grid>
    </Scrollbar>
  );
}
