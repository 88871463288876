import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Tooltip, Grid, Typography, Stack } from '@mui/material';
import { renderColorBox } from '../../utils/cssStyles';
import { cloudinaryThumbnail, cloudinaryFull } from '../../utils/formatCloudinary';
import Image from '../image';
import Lightbox from '../lightbox';
import Iconify from '../iconify';
import { fMonthDayYear, fDateTime, timestamp, fDate } from '../../utils/formatTime';
import { fCurrency } from '../../utils/formatNumber';

const renderIcon = (value, icon) => (
  <Box>
    <Iconify icon={icon} width={13} height={13} /> {value || ''}
  </Box>
);

const orderNumber = {
  field: 'orderNumber',
  headerName: 'PO #',
  width: 90,
  type: 'customText',
  valueGetter: (params) => `${params.row.orderNumber}-${params.row?.orderMessages?.map((message) => message)}-${(params.row?.orderDelay ?
    "delayed" : "")}-${params.row.deleteFlag ? `Deleted by ${params.row.deletedBy} on: ${fMonthDayYear(params.row.dateDeleted)}` : ''}`,
  renderCell: (params) => (
    <>
      <Tooltip
        title={
          params.row?.orderMessages?.length > 0 || params.row?.deleteFlag ? (
            <>
              {params.row?.deleteFlag && (
                <>
                  <div>
                    Deleted by {params.row.deletedBy} on: {fMonthDayYear(params.row.dateDeleted)}
                  </div>
                  <br />
                </>
              )}
              {params.row?.orderMessages?.map((message) => (
                <div key={message}>
                  {message}
                  <br />
                  <br />
                </div>
              ))}
            </>
          ) : (
            ''
          )
        }
      >
        <Box
          sx={{
            color: params.row?.deleteFlag ? 'error.main' : params.row?.orderMessages?.length > 0 && 'info.main',
          }}
        >
          {params.row.orderNumber}
        </Box>
      </Tooltip>
    </>
  ),
};
const orderedFor = {
  field: 'orderedFor',
  headerName: 'Ordered For',
  minWidth: 120,
  flex: 1,
  type: 'customSelect',
};
const itemCount = {
  field: 'itemCount',
  headerName: 'Items',
  minWidth: 90,
  flex: 1,
  type: 'customNumber',
};

const orderedOn = {
  field: 'orderedOn',
  headerName: 'Ordered On',
  minWidth: 120,
  flex: 1,
  type: 'customDate',
  renderCell: (params) => renderIcon(fDateTime(params.value), 'material-symbols:calendar-today-outline'),
  valueGetter: (params) => new Date(params.value),
};
const department = {
  field: 'department',
  headerName: 'Department',
  minWidth: 110,
  flex: 0.75,
  renderCell: (params) => renderColorBox(params.value, 'secondary'),
  valueGetter: (params) => params.row.department,
  type: 'customSelect',
};
const qty = { field: 'itemCount', headerName: 'Qty', minWidth: 50, flex: 0.5 };
const quantity = {
  field: 'quantity',
  headerName: 'Qty',
  renderCell: (params) => params.value > 0 && params.value,
  flex: 0.5,
};

const dateAdded = {
  field: 'dateAdded',
  headerName: 'Submitted On',
  type: 'customDate',
  minWidth: 100,
  flex: 1.2,
  renderCell: (params) => renderIcon(fDateTime(params.row.dateAdded), 'material-symbols:calendar-today-outline'),
  valueGetter: (params) => {
    const arrayHasCurrentUser = params.row?.facilityManagerAssignments?.some((chip) => chip.currentUser);
    //manipulate the date to make it sortable with the facility manager on top
    return `${arrayHasCurrentUser ? 1 : 0}${new Date(params.value)}`;
  },
};
const group = {
  field: 'group',
  headerName: 'Group',
  type: 'customSelect',
  minWidth: 150,
  flex: 1.2,
};
const approvedOn = {
  field: 'dateApproved',
  headerName: 'Approved on',
  type: 'customDate',
  minWidth: 100,
  flex: 1,
  renderCell: (params) => renderIcon(fDateTime(params.value), 'material-symbols:calendar-today-outline'),
  valueGetter: (params) => (params.value ? new Date(params.value) : null),
};

const total = {
  field: 'total',
  headerName: 'Total',
  minWidth: 70,
  flex: 0.5,
  renderCell: (params) => fCurrency(params.value),
};

const description = {
  field: 'description',
  headerName: 'Description',
  width: 300,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        fontSize: '0.7rem',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),
  type: 'customText',
};
const sentTo = {
  field: 'sentTo',
  headerName: 'Sent To',
  minWidth: 90,
  flex: 1,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),
  type: 'customText',
};
const type = {
  field: 'type',
  headerName: 'Type',
  minWidth: 100,
  flex: 1,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),
  type: 'customText',
};

const timeStamp = {
  field: 'timeSent',
  headerName: 'Sent on',
  flex: 0.8,
  renderCell: (params) => renderIcon(fDateTime(params.value), 'material-symbols:calendar-today-outline'),
  valueGetter: (params) => new Date(params.value),
  type: 'customDate',
};

const descriptionWide = {
  field: 'description',
  headerName: 'Description',
  minWidth: 100,
  flex: 2,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        fontSize: '0.7rem',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),
  type: 'customText',
};

const facilityShortName = {
  field: 'facilityShortName',
  headerName: 'Facility',
  minWidth: 110,
  flex: 0.75,
  type: 'customSelect',
  renderCell: (params) => (
    <>
   { params.row.facilityShortName}
  </>)
};
const facility = {
  field: 'facility',
  headerName: 'Facility',
  minWidth: 90,
  flex: 0.75,
  type: 'customSelect',
};
const personFor = {
  field: 'personFor',
  headerName: 'On Behalf Of',
  minWidth: 80,
  flex: 1,
  type: 'customSelect',
};
const vendorShipDate = {
  field: 'vendorShipDate',
  headerName: 'Ship Day',
  headerAlign: 'left',
  minWidth: 90,
  flex: 1,
  renderCell: (params) => (
    <Tooltip
      title={
        (params.row.requestedShipDate && params.row.vendorShipDate && params.row.vendorCutoff && (
          <div>
            <div>Original ship date</div>
            <div>{params.row.vendorShipDate}</div>
            <div>{params.row.vendorCutoff} </div>
          </div>
        )) ||
        (params.row.requestedShipDate && params.row.vendorShipDate && (
          <div>
            <div>Original ship date</div>
            <div>{params.row.vendorShipDate}</div>
          </div>
        )) ||
        (params.row.vendorCutoff && <div>{params.row.vendorCutoff}</div>)
      }
      placement="top"
      arrow
    >
      <Box
        component="div"
        sx={{
          fontSize: '0.75rem',
          wordWrap: 'break-word',
          whiteSpace: 'pre-wrap',
          color: params.row.vendorCutoff && 'info.main',
        }}
      >
        {params.row.requestedShipDate ? `Requested | ${params.row.requestedShipDate}` : params.row.vendorShipDate}
        {params.row.requestedShipDate && params.row.vendorShipDate && (
          <Box sx={{ color: 'info.main', display: 'inline' }}>
            <Iconify
              icon="material-symbols:info-outline"
              width={18}
              height={18}
              style={{ paddingTop: '5px', paddingLeft: '2px' }}
            />
          </Box>
        )}
      </Box>
    </Tooltip>
  ),
  valueGetter: (params) => params.row.vendorShipDate,
  type: 'customText',
};
const submittedBy = {
  field: 'submittedBy',
  headerName: 'Submitted By',
  minWidth: 120,
  flex: 1,
  type: 'customSelect',
};
export default function RenderImage({ params }) {
  RenderImage.propTypes = {
    params: PropTypes.object,
  };
  const { pictures } = params.row;
  const picture = pictures[0];
  const [selectedImage, setSelectedImage] = useState(-1);
  const handleOpenLightbox = () => {
    setSelectedImage(0);
  };
  const handleCloseLightbox = () => {
    setSelectedImage(-1);
  };
  return (
    <>
      <Image
        alt="attachment"
        src={cloudinaryThumbnail(picture)}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            opacity: 0.9,
          },
        }}
        onClick={() => handleOpenLightbox()}
      />
      <Lightbox
        index={0}
        slides={pictures?.map((picture) => ({ src: cloudinaryFull(picture) }))}
        open={selectedImage >= 0}
        close={handleCloseLightbox}
      />
    </>
  );
}

const picture = {
  field: 'picture',
  headerName: 'Image',
  flex: 0.4,
  renderCell: (params) => <RenderImage params={params} />,
  sortable: false,
  filterable: false,
};
const ordered = {
  field: 'ordered',
  headerName: 'Ordered/Approved',
  minWidth: 90,
  flex: 1,
  renderCell: (params) => `${params.row.quantityOrdered} | ${params.row.quantityApproved}`,
};
const originalPrice = {
  field: 'originalPrice',
  headerName: 'Price',
  minWidth: 80,
  flex: 1,
  renderCell: (params) => fCurrency(params.value),
};
const status = {
  field: 'status',
  headerName: 'Status',
  minWidth: 90,
  flex: 1,
};
const vendor = {
  field: 'vendor',
  headerName: 'Vendor',
  minWidth: 90,
  flex: 1,
};

const uom = {
  field: 'uom',
  headerName: 'UOM',
  renderCell: (params) =>
    `${params.row.uomAmount ? params.row.uomAmount : ''}/${params.row.uomType ? params.row.uomType : ''}`,
  minWidth: 60,
  flex: 0.4,
  type: 'customSelect',
};

const statusCode = {
  field: 'statusCode',
  flex: 1.5,
  headerName: 'Status',
  renderCell: (params) => (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item>
        {' '}
        {params.row?.statusUpdates?.length > 0 && (
          <Tooltip
            title={
              params.row?.statusUpdates?.length > 0
                ? params.row?.statusUpdates?.map((status) => (
                    <div key={status}>
                      <div>Status: {status.status}</div>
                      <div>Date: {fMonthDayYear(status.dateAdded)}</div>
                      <div>Updated by: {status.updatedBy}</div>
                      <br />
                      <br />
                    </div>
                  ))
                : ''
            }
          >
            <Iconify color="info" icon="material-symbols:info-outline" width={16} height={16} />
          </Tooltip>
        )}
      </Grid>
      <Grid item>
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <Box
            component="div"
            sx={{
              wordWrap: 'break-word',
              whiteSpace: 'pre-wrap',
            }}
          >
            {params.value}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  ),
};
const tickets = {
  field: 'ticketIds',
  headerName: 'Ticket #',
  flex: 1,
  minWidth: 80,
  type: 'customSelect',
  renderCell: (params) => (
    <Stack direction="column" key={params.value}>
      {params.value?.map((value) => (
        <>
          <Box
            sx={{
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            onClick={(event) => {
              if (value > 0) {
                event.navigateToTickets = value;
              }
            }}
          >
            <Typography
              variant="subtitle2"
              component="span"
              sx={{
                textDecoration: 'underline',
              }}
            >
              <div>{value}</div>
            </Typography>
          </Box>
        </>
      ))}
    </Stack>
  ),
};

const PENDING_ORDERS = [
  orderNumber,
  facilityShortName,
  vendorShipDate,
  department,
  personFor,
  dateAdded,
  submittedBy,
  qty,
  total,
  tickets,
];
const COMPLETED_ORDERS = [
  orderNumber,
  facility,
  group,
  department,
  personFor,
  dateAdded,
  submittedBy,
  approvedOn,
  qty,
  total,
];
const TICKET_ORDERS = [description, quantity, statusCode];
const COPY_ORDER = [orderNumber, orderedFor, orderedOn, itemCount];
const VIEW_ORDER_HISTORY = [sentTo, type, timeStamp];
const BUDGET_HISTORY = [picture, orderNumber, descriptionWide, ordered, originalPrice, uom, vendor,status, orderedOn];
export { BUDGET_HISTORY, PENDING_ORDERS, COMPLETED_ORDERS, TICKET_ORDERS, COPY_ORDER, VIEW_ORDER_HISTORY };
