import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, Paper, IconButton, styled, Typography, Link, Tooltip, Box } from '@mui/material';
import Iconify from '../../../../reusable-components/iconify';
import Image from '../../../../reusable-components/image';
import Lightbox from '../../../../reusable-components/lightbox';
import { useSelector, useDispatch } from '../../../../redux/store';
import { addItemToCart, removeItemFromCart, clearBadgeFromRedux } from '../../../../redux/slices/rentals';

RentalItem.propTypes = {
  item: PropTypes.object,
};

export default function RentalItem({ item }) {
  const dispatch = useDispatch();
  const {
    data: { cart },
  } = useSelector((state) => state.rentals);
  const { user } = useSelector((state) => state.user);
    const [currentItem, setCurrentItem] = useState(null);
  const location = useLocation();
  const residentId = Number(new URLSearchParams(location.search).get('residentId'));

    useEffect(() => {
        const currentRental = cart.find((cartItem) => cartItem.itemId === item.id && cartItem.residentId === residentId);
        if (currentRental) {
            setCurrentItem(currentRental);
        } else {
            setCurrentItem(null);
        }
    }, [cart, residentId, item.id]);

  const CustomIconButton = styled(Iconify)({
    cursor: 'pointer',
    color: '#fff',
  });

    const handleAddCart = () => {
    dispatch(addItemToCart(item.id, residentId, user.id, currentItem?.cartId ?? 0));
    setTimeout(() => {
      dispatch(clearBadgeFromRedux());
    }, 1000);
  };
  const handleRemoveCart = () => {
    dispatch(removeItemFromCart(currentItem.cartItemId));
  };
  const [selectedImage, setSelectedImage] = useState(-1);
  const handleOpenLightbox = () => {
    setSelectedImage(0);
  };

  const handleCloseLightbox = () => {
    setSelectedImage(-1);
  };

  return (
    <Grid item key={item} xs={3} style={{ maxWidth: '300px' }}>
      <Paper
        key={item.id}
        elevation={1}
        sx={{
          textAlign: 'center',
          cursor: 'pointer',
          position: 'relative',
          padding: '4px',
          width: '200px',
          height: '250px',
        }}
      >
      {currentItem?.quantity > 0 ? (
          <div style={{ padding: '20px' }}>
            <AddToCartDiv>
              <CustomIconButton icon="lucide:trash" width={12} height={12} onClick={() => handleRemoveCart()} />
              <span>&nbsp;&nbsp;</span>
              <span style={{ color: '#fff' }}>{currentItem.quantity}</span>
              <span>&nbsp;&nbsp;</span>{' '}
              <CustomIconButton
                icon="iconamoon:sign-plus-bold"
                width={12}
                height={12}
                onClick={() => handleAddCart()}
              />
            </AddToCartDiv>
          </div>
        ) : (
          <IconButton
            onClick={() => handleAddCart()}
            sx={{
              right: -20,
              top: -6,
              ml: 15,
              position: 'relative',
              color: '#b128bc',
            }}
          >
            <Tooltip title={`Add to Cart`}>
              <Iconify icon="iconamoon:sign-plus-circle-fill" width={24} height={24} />
            </Tooltip>
          </IconButton>
        )}
        <Image
          alt="attachment"
          src={item.image}
          sx={{ borderRadius: 1.5, width: 180, maxHeight: '200px', objectFit: 'contain' }}
          onClick={() => handleOpenLightbox()}
        />{' '}
        <Lightbox index={0} slides={[{ src: item.Image }]} open={selectedImage >= 0} close={handleCloseLightbox} />
      </Paper>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', maxWidth: '200px' }}>
        <Typography variant="h8" sx={{ color: '#8cc53f', fontWeight: 'bold', mt: 1 }}>
          ${item.price}
        </Typography>
        <Typography variant="subtitle2">{item.description}</Typography>
        <Box sx={{ display: 'inline-flex', flexDirection: 'row', alignItems: 'baseline' }}>
          <Typography variant="subtitle2">H&R: </Typography>
          <Typography variant="subtitle2" sx={{ maxWidth: '200', color: '#4ca5e7', textDecoration: 'underline' }}>
            <Tooltip title={`Proceed to Vendor Site`}>
              <Link
                href="https://handrhealthcare.com/"
                color="#4ca5e7"
                variant="inherit"
                target="_blank"
                style={{ fontSize: '12px' }}
              >
                {' '}
                {item.id}
              </Link>
            </Tooltip>
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(item.id);
              }}
              sx={{
                position: 'relative',
                color: '#b128bc',
                '&:hover': {
                  cursor: 'copy',
                  title: 'copy to clipboard',
                },
              }}
            >
              <Tooltip title={`Copy to clipboard`}>
                <Iconify icon="tabler:copy" width={16} height={16} />
              </Tooltip>
            </IconButton>
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
}
const AddToCartDiv = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: 0,
  top: 7,
  height: 22,
  borderRadius: '10px',
  backgroundColor: '#b128bc',
  color: 'secondary',
  padding: theme.spacing(0.5),
  margin: '2.5px 3px 2.5px 0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  zIndex: '2',
}));
