import axios from '../../utils/axios';
import { objectToFormData } from '../utils';

// GET
// /Api/Budget/BudgetData
export const getBudgetData = async () => {
  const response = await axios.get('/Budget/BudgetData');
  return response;
};

// POST
// /Api/Budget/CreateGLCode glCode facilityId
export const postCreateGLCode = async (glCode, facilityBookingId) => {
  const response = await axios.post(`/Budget/CreateGLCode?glCode=${glCode}&facilityBookingId=${facilityBookingId}`);
  return response;
};

// POST
// /Api/Budget/DeleteBudget id
export const postDeleteBudget = async (id) => {
  const response = await axios.post(`/Budget/DeleteBudget?id=${id}`);
  return response;
};

// GET
// /Api/Budget/BudgetsByFacility facilityId
export const getBudgetsByFacility = async (facilityId, budgetDate) => {
  const date = budgetDate.toJSON()
  const response = await axios.get(`/Budget/BudgetsByFacility?facilityId=${facilityId}&month=${date}`);
  return response;
};

// GET
// /Api/Budget/DownloadBudgets
//Download Api is used in code bec we need the `${HOST_API_URL}

// POST
// /Api/Budget/CopyBudget facilityId-int facilityCopyId-int useGLs-boolean agoraCategoryIds-[]
export const postCopyBudget = async (facilityId, facilityCopyId, useGLs, agoraCategoryIds) => {
  const updatedForm = {
    facilityId,
    facilityCopyId,
    useGLs,
    agoraCategoryIds: agoraCategoryIds.map((item) => item.id),
  };

  const formData = objectToFormData({
    ...updatedForm,
  });

  const response = await axios.post('Budget/CopyBudget', formData);
  return response;
};

//Post
// /Api/Budget/BudgetForm Id PPDBudget MidBudgetCategoryId AgoraSubcategoryId ExcludeFromMain
export const postBudgetForm = async (form, id) => {
  const updatedForm = {
    PPDBudget: form.ppdBudget,
    FacilityId: form.facility.id,
    AgoraCategoryId: form.agoraCategory.id,
    AgoraSubcategoryId: form.agoraSubcategory.id,
    ExcludeFromMain: form.excludeFromMain,
    GLId: form.glCode.id,
  };

  if (id) {
    updatedForm.Id = id;
  }

  const formData = objectToFormData({
    ...updatedForm,
  });

  const response = await axios.post('Budget/BudgetForm', formData);
  return response;
};
