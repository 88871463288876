import PropTypes from 'prop-types';
import { TextField, Tooltip, Typography } from '@mui/material';
import Iconify from '../../../../../reusable-components/iconify/Iconify';

IntegrationTextFormField.propTypes = {
  value: PropTypes.string,
  header: PropTypes.string,
  tooltip: PropTypes.string,
  onChange: PropTypes.func
};

export default function IntegrationTextFormField({ value, onChange, header, tooltip }) {

  return <>
    <Typography variant="subtitle1" sx={{ mt: 1 }}>
      {header}
    </Typography>
    <TextField
      fullWidth
      size="small"
      value={value ?? ""}
      onChange={onChange}
      sx={{
        mt: 1,
        '& legend': { display: 'none' },
        '& fieldset': { top: 0 },
        '& .MuiFormLabel-root ': { display: 'none' },
      }}
    />
    {tooltip && <Tooltip title={tooltip}>
      <Iconify icon="material-symbols:info-outline" width={18} height={18} />
    </Tooltip>}
  </>;
}