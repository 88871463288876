import PropTypes from 'prop-types';
import { useEffect, useState, useRef, useCallback } from 'react';
import { Box } from '@mui/material';
import { dropTargetForElements, monitorForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { reorder } from "@atlaskit/pragmatic-drag-and-drop/reorder";
import { attachClosestEdge, extractClosestEdge } from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import DragDropIndicator from './DragDropIndicator';

DragDropArea.propTypes = {
  setInputGroups: PropTypes.func,
  show: PropTypes.bool,
  position: PropTypes.oneOf(["top", "bottom"]),
};

export default function DragDropArea({ setInputGroups, position, show }) {
  const columnRef = useRef(null);
  const [closestEdge, setClosestEdge] = useState(null);

  useEffect(() => {
    const columnEl = columnRef.current;

    return dropTargetForElements({
      element: columnEl,
      getIsSticky: () => true,
      getData: ({ input, element }) => attachClosestEdge({ type: "non-column-drop", position }, { input, element, allowedEdges: ["top", "bottom"] }),
      onDragEnter: (args) => setClosestEdge(extractClosestEdge(args.self.data)),
      onDragLeave: () => setClosestEdge(null),
      onDrop: () => setClosestEdge(null),
    });
  }, []);

  const handleDrop = useCallback(({ source, location }) => {
    if (!location.current.dropTargets.some(target => target.data.type === "non-column-drop") || (source.data.type !== "column" && source.data.type !== "row")) return; 
    const sourceColumnId = location.initial.dropTargets.find(target => target.data.type === "column")?.data.columnId;
    const dropPosition = location.initial.dropTargets.find(target => target.data.type === "non-column-drop")?.data.position;
    if (sourceColumnId === null) return;
    setInputGroups(groups => {
      if (source.data.type === "column") {
        const sourceIndex = groups.findIndex(group => group.groupId === sourceColumnId);
        if (sourceIndex === -1) return groups;
        const sortedGroups = reorder({ list: [...groups], startIndex: sourceIndex, finishIndex: dropPosition === "top" ? groups.length - 1 : 0 });
        return [...sortedGroups].filter(group => group && (!group.miscellaneousFieldsGroup || group.fields?.length > 0));
      }
      const sourceColumnData = groups.find(group => group.groupId === sourceColumnId);
      if (!sourceColumnData) return groups;
      const fieldToMove = sourceColumnData.fields?.find(field => field.id === source.data.rowId);
      if (!fieldToMove) return groups;
      sourceColumnData.fields = sourceColumnData.fields.filter(field => field.id !== source.data.rowId);
      const newGroupId = (groups.reduce((prev, current) => (prev && prev.groupId > current.groupId) ? prev : current, null)?.groupId ?? 0) + 1;
      const newGroup = { groupId: newGroupId, fields: [fieldToMove], miscellaneousFieldsGroup: true };
      return (position === "top" ? [newGroup, ...groups] : [...groups, newGroup])
        .filter(group => group && (!group.miscellaneousFieldsGroup || group.fields?.length > 0));
    });
  }, [setInputGroups, position]);

  useEffect(() => monitorForElements({ onDrop: handleDrop }), [handleDrop]);

  return <Box ref={columnRef} sx={{
    display: show ? "block" : "none",
    width: "100%",
    paddingTop: position === "top" ? "5px" : "10px",
    paddingBottom: position === "top" ? "10px" : "5px",
  }}>
    {closestEdge && <DragDropIndicator />}
  </Box>;
}