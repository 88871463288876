import { useState, useEffect } from 'react';
import CustomDataGrid from '../../../reusable-components/datagrid/CustomDataGrid';
import { useSnackbar } from '../../../reusable-components/snackbar';
import { getTicketTypes, postDeleteTicketType } from '../../../api/tickets';
import { ConfirmDialog } from '../../../reusable-components/confirm-dialog';
import EditIconButton from '../../../reusable-components/basic-buttons/EditIconButton';
import TicketTypeFormModal from './ticketType/TicketTypeFormModal';
import AddButton from '../../../reusable-components/basic-buttons/AddButton';

export default function TicketTypes()
{
  const { enqueueSnackbar } = useSnackbar();

  const [ticketTypes, setTicketTypes] = useState([]);
  const [fieldTypes, setFieldTypes] = useState([]);
  const [row, setRow] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getTicketTypes()
      .then(res => {
        setTicketTypes(res.data.ticketTypes);
        setFieldTypes(res.data.ticketTypeFieldTypes.map(type => ({
          value: type.id,
          label: type.name,
          type: type.type,
          defaultType: type.defaultType,
          multiple: type.multiple
        })));
      })
      .catch(() => enqueueSnackbar('Failed to fetch ticket types', { variant: 'error' }))
      .finally(() => setLoading(false));
  }, []);

  const deleteType = (id) => {
    postDeleteTicketType(id)
      .then(() => {
        setTicketTypes(data => data.filter(row => row.id !== id));
        enqueueSnackbar('Ticket type deleted successfully', { variant: 'success' })
      })
      .catch(() => enqueueSnackbar('Failed to delete ticket type', { variant: 'error' }));
  };

  const gridColumns = [
    {
      field: 'name',
      headerName: 'Name',
      type: 'customText',
      flex: 1
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      id: 'actions',
      renderCell: (params) => <>
        <EditIconButton onClick={() => { setRow(params.row); setOpenForm(true); }} />
        <ConfirmDialog
          content={`Are you sure you want to delete ticket type - ${params.row.name}?`}
          action={() => deleteType(params.row.id)}
        />
      </>
    }
  ];

  return (
    <>
      <CustomDataGrid
        gridId="tickets-types-view"
        boxSX={{ height: 'calc(100vh - 300px)' }}
        data={ticketTypes}
        gridColumns={gridColumns}
        CustomLeftToolbar={() => <AddButton onClick={() => { setRow(null); setOpenForm(true); }} >Add Type</AddButton> }
        loading={loading}
      />
      <TicketTypeFormModal
        open={openForm}
        fieldType={row}
        onClose={() => { setRow(null); setOpenForm(false); }}
        onSave={newData => {
          setRow(null);
          setOpenForm(false);
          if (newData) setTicketTypes(fullData => [...fullData.filter(dataRow => dataRow.id !== newData.id), newData]);
        }}
        fieldTypes={fieldTypes}
      />
    </>
  );
}
