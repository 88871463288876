/* eslint-disable jsx-a11y/label-has-associated-control */
import { useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Dialog, DialogTitle, DialogContent, IconButton, Paper, Typography, Box, Button } from '@mui/material';
import { Document, Page } from 'react-pdf';
import { splitPdf } from '../../../api/invoicing';
import Iconify from '../../../reusable-components/iconify';
import { AwaitButton } from '../../../reusable-components/await-button';

export default function SplitPDF() {
  const fileInputRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [cutLines, setCutLines] = useState([]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: openDropzone,
  } = useDropzone({
    accept: {
      'application/pdf': [],
    },
    onDrop: (acceptedFiles) => {
      setPdfFile(acceptedFiles[0]);
    },
  });

  const handleClickOpen = () => {
    setPdfFile(null);
    setCutLines([]);
    setOpen(true);
  };
  const handleClose = () => {
    setPdfFile(null);
    setCutLines([]);
    setOpen(false);
  };

  const split = async () => {
    const response = await splitPdf(cutLines, pdfFile);
    if (response.status === 200) {
      // Step 1: Create a Blob from the response data
      const blob = new Blob([response.data], { type: 'application/zip' });

      // Step 2: Create a URL for the Blob
      const downloadUrl = window.URL.createObjectURL(blob);

      // Step 3: Trigger the download
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'file.zip'); // Name the download file here
      document.body.appendChild(link);
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);

      handleClose();
    }
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    setPdfFile(file);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const toggleCutLine = (pageNumber) => {
    setCutLines((prevLines) =>
      prevLines.includes(pageNumber) ? prevLines.filter((page) => page !== pageNumber) : [...prevLines, pageNumber]
    );
  };

  const renderCutLines = (pageNumber) =>
    pageNumber !== 0 &&
    cutLines.includes(pageNumber) && (
      <div
        style={{
          position: 'absolute',
          top: -8,
          left: 0,
          width: '100%',
          height: '2px',
          backgroundColor: 'yellow',
        }}
      />
    );

  const renderButton = (pageNumber) => {
    const isCutLinePresent = cutLines.includes(pageNumber);

    return pageNumber !== 0 ? (
      <Button
        onClick={() => toggleCutLine(pageNumber)}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          color: isCutLinePresent ? 'red' : 'yellow',
        }}
      >
        {isCutLinePresent ? 'Remove Cut Line' : 'Add Cut Line'}
      </Button>
    ) : null;
  };

  const renderPages = () => {
    const pages = [];
    for (let i = 0; i < numPages; i++) {
      pages.push(
        <Box
          key={i}
          sx={{
            mb: 2,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            '& .react-pdf__Page__annotations': {
              display: 'none',
            },
          }}
        >
          <Page pageNumber={i + 1} renderTextLayer={false} />
          {renderCutLines(i)}
          {renderButton(i)}
        </Box>
      );
    }
    return pages;
  };

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        color="secondary"
        onClick={handleClickOpen}
        startIcon={<Iconify icon="mdi:scissors" />}
      >
        Split PDF
      </Button>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle
          sx={{
            textAlign: 'center',
          }}
        >
          Split PDF
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
          {cutLines.length > 0 && (
            <AwaitButton
              variant="outlined"
              style={{
                position: 'absolute',
                right: 48,
                top: 64,
                color: 'primary',
              }}
              onClick={split}
              startIcon={<Iconify icon="mdi:scissors" />}
            >
              Split PDF
            </AwaitButton>
          )}
        </DialogTitle>
        <DialogContent>
          {!pdfFile ? (
            <Box {...getRootProps()} sx={{ cursor: 'pointer' }} onClick={openDropzone}>
              <input {...getInputProps()} />
              <Paper
                elevation={isDragActive ? 6 : 1}
                sx={{
                  p: 2,
                  ml: 4,
                  bgcolor: 'background.paper',
                  color: 'info.main',
                  borderColor: 'info.main',
                  border: 1,
                  transition: 'all 0.2s ease-in-out',
                  '&:hover': {
                    bgcolor: 'background.default',
                    boxShadow: 3,
                  },
                }}
              >
                <Typography variant="body2" textAlign="center">
                  Click to Upload or Drag and Drop
                </Typography>
              </Paper>
            </Box>
          ) : (
            <Box sx={{ width: '100%', background: '#444' }}>
              <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
                {renderPages()}
              </Document>
            </Box>
          )}
        </DialogContent>
      </Dialog>
      <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileInputChange} />
    </>
  );
}
