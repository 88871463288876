import PropTypes from 'prop-types';
import { useEffect, useState, useRef, useCallback } from 'react';
import { Typography, Box } from '@mui/material';
import { dropTargetForElements, monitorForElements, draggable } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { getReorderDestinationIndex } from "@atlaskit/pragmatic-drag-and-drop-hitbox/util/get-reorder-destination-index"; 
import { reorder } from "@atlaskit/pragmatic-drag-and-drop/reorder";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import { attachClosestEdge, extractClosestEdge } from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import TicketFieldGroupConfig from './TicketFieldGroupConfig';
import TicketTypeFieldForm from './TicketTypeFieldForm';
import AddButton from '../../../../reusable-components/basic-buttons/AddButton';
import DragDropIndicator from './DragDropIndicator';

TicketFieldGroupForm.propTypes = {
  editGroup: PropTypes.object,
  fieldTypes: PropTypes.array,
  setFieldValue: PropTypes.func,
  inputGroups: PropTypes.array,
  setInputGroups: PropTypes.func,
  openFieldConfig: PropTypes.number,
  setOpenFieldConfig: PropTypes.func,
  openGroupConfig: PropTypes.number,
  setOpenGroupConfig: PropTypes.func,
  setSubject: PropTypes.func,
  setShowEndDropAreas: PropTypes.func,
};

export default function TicketFieldGroupForm({ editGroup, fieldTypes, setFieldValue, inputGroups, setInputGroups, openFieldConfig, setOpenFieldConfig, openGroupConfig,
  setOpenGroupConfig, setSubject, setShowEndDropAreas })
{
  const columnRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [closestEdge, setClosestEdge] = useState(null);

  useEffect(() => {
    const columnEl = columnRef.current;

    return combine(draggable({
      element: columnEl,
      getInitialData: () => ({ type: "column", columnId: editGroup.groupId }),
      onDragStart: () => { setIsDragging(true); setShowEndDropAreas(true); },
      onDrop: () => { setIsDragging(false); setShowEndDropAreas(false); },
    }), dropTargetForElements({
      element: columnEl,
      getIsSticky: () => true,
      getData: ({ input, element }) => {
        const data = { type: "column", columnId: editGroup?.groupId ?? 0 };
        return attachClosestEdge(data, {
          input,
          element,
          allowedEdges: ["top", "bottom"],
        });
      },
      onDragEnter: (args) => {
        if (args.source.data.type !== "column" || args.source.data.columnId === editGroup?.groupId) return;
        setClosestEdge(extractClosestEdge(args.self.data));
      },
      onDrag: (args) => {
        if (args.source.data.type !== "column" || args.source.data.columnId === editGroup?.groupId) return;
        setClosestEdge(extractClosestEdge(args.self.data));
      },
      onDragLeave: () => setClosestEdge(null),
      onDrop: () => setClosestEdge(null),
    }));
  }, [editGroup]); 

  const handleDrop = useCallback(({ source, location }) => {
    if (!location.current.dropTargets.length) return;
    const sourceColumnId = location.initial.dropTargets.find(target => target.data.type === "column")?.data.columnId;
    const destinationColumn = location.current.dropTargets.find(target => target.data.type === "column");
    const destinationColumnId = destinationColumn?.data?.columnId;
    if (sourceColumnId === null) return;
    const groupFilter = (groups) => groups.filter(group => group && (!group.miscellaneousFieldsGroup || group.fields?.length > 0));

    setInputGroups(groups => {
      const sourceColumnData = groups.find(group => group.groupId === sourceColumnId);
      const destinationColumnData = groups.find(group => group.groupId === destinationColumnId);
      if (!sourceColumnData || !destinationColumnData) return groups;
      if (source.data.type === "row" && !destinationColumnData.miscellaneousFieldsGroup) {
        const draggedRowIndex = sourceColumnData.fields?.findIndex((field) => field.id === source.data.rowId);
        if (draggedRowIndex === null) return groups;
        const destinationRow = location.current.dropTargets.find(target => target.data.type === "row");
        const closestEdgeOfTarget = destinationRow ? extractClosestEdge(destinationRow.data) : null;
        const indexOfTarget = destinationRow ? destinationColumnData.fields.findIndex((field) => field.id === destinationRow.data.rowId) : 0;
        const destinationIndex = getReorderDestinationIndex({ startIndex: draggedRowIndex, indexOfTarget, closestEdgeOfTarget, axis: "vertical" });
        if (sourceColumnId === destinationColumnId) {
          sourceColumnData.fields = [...reorder({ list: [...sourceColumnData.fields], startIndex: draggedRowIndex, finishIndex: destinationIndex })];
          return groupFilter(groups);
        }
        if (draggedRowIndex === -1) return groups;
        const fieldToMove = sourceColumnData.fields[draggedRowIndex];
        if (!fieldToMove) return [...groups];
        const newDestinationCards = Array.from(destinationColumnData.fields);
        const newIndexInDestination = !destinationRow ? 0 : closestEdgeOfTarget === "bottom" ? indexOfTarget + 1 : indexOfTarget;
        newDestinationCards.splice(newIndexInDestination, 0, fieldToMove);
        sourceColumnData.fields = sourceColumnData.fields.filter((field) => field.id !== fieldToMove.id);
        destinationColumnData.fields = newDestinationCards;
        return groupFilter(groups);
      }
      if (source.data.type !== "column" && !(source.data.type === "row" && destinationColumnData.miscellaneousFieldsGroup)) return groups;
      const closestEdgeOfTarget = extractClosestEdge(destinationColumn.data);
      const destinationColumnIndex = groups.findIndex((group) => group.groupId === destinationColumnId);
      const sourceColumnIndex = groups.findIndex((group) => group.groupId === sourceColumnId);
      const destinationIndex = getReorderDestinationIndex({ startIndex: sourceColumnIndex, indexOfTarget: destinationColumnIndex, closestEdgeOfTarget, axis: "vertical" });
      if (!sourceColumnData.miscellaneousFieldsGroup && destinationColumnData.miscellaneousFieldsGroup && source.data.type === "row") {    
        const draggedRowIndex = sourceColumnData.fields?.findIndex((field) => field.id === source.data.rowId);
        if (draggedRowIndex === null) return groups;
        const fieldToMove = sourceColumnData.fields.find(field => field.id === source.data.rowId);
        if (!fieldToMove) return groups;
        sourceColumnData.fields = sourceColumnData.fields.filter(field => field.id !== source.data.rowId);
        const newGroupId = (groups.reduce((prev, current) => (prev && prev.groupId > current.groupId) ? prev : current, null)?.groupId ?? 0) + 1;
        const groupList = [...groups, { groupId: newGroupId, fields: [fieldToMove], miscellaneousFieldsGroup: true }]
          .filter(group => group && (!group.miscellaneousFieldsGroup || group.fields?.length > 0));
        const sortedGroups = reorder({ list: [...groupList], startIndex: groupList.length - 1, finishIndex: destinationIndex });
        return groupFilter([...sortedGroups]);
      }
      const sortedGroups = reorder({ list: [...groups], startIndex: sourceColumnIndex, finishIndex: destinationIndex });
      return groupFilter([...sortedGroups]);
    });
  }, [setInputGroups]);

  useEffect(() => monitorForElements({ onDrop: handleDrop }), [handleDrop]);

  const addNewFieldToGroup = () => {
    setInputGroups(groups => {
      const groupToUpdate = groups.find(updateGroup => updateGroup.groupId === editGroup.groupId);
      const newFieldId = (groups.flatMap(group => group.fields.map(field => field.id)).reduce((prev, current) => (prev > current) ? prev : current, 0)) + 1;
      groupToUpdate.fields = [...groupToUpdate.fields, { id: newFieldId }]
      return [...groups];
    })
    setOpenFieldConfig(-1);
  }

  return <Box sx={{ marginBottom: editGroup.miscellaneousFieldsGroup ? "15px" : "25px", marginTop: !editGroup.miscellaneousFieldsGroup ? "10px" : "0px", opacity: isDragging ? .5 : 1 }}>
    {closestEdge === "top" && <DragDropIndicator />}
    <Box ref={columnRef} sx={editGroup.miscellaneousFieldsGroup ? {} : { backgroundColor: "#f0f0f0", padding: "10px", borderRadius: "5px", boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }}>
      {editGroup.groupId > -1 && !editGroup.miscellaneousFieldsGroup && <Typography variant="subtitle1" sx={{ mt: 1 }}>{editGroup.groupLabel}
        <TicketFieldGroupConfig
          open={editGroup.groupId === openGroupConfig}
          setOpen={setOpenGroupConfig}
          setGroups={setInputGroups}
          group={editGroup}
        />
      </Typography>}
      {editGroup.fields.map((field, index) => <TicketTypeFieldForm
        key={index}
        index={index}
        group={editGroup}
        field={field}
        fieldTypes={fieldTypes}
        setFieldValue={setFieldValue}
        openFieldConfig={openFieldConfig}
        setOpenFieldConfig={setOpenFieldConfig}
        inputGroups={inputGroups}
        setInputGroups={setInputGroups}
        setSubject={setSubject}
        setShowEndDropAreas={setShowEndDropAreas}
      />)}
      {!editGroup.miscellaneousFieldsGroup && <Typography variant="subtitle1" sx={{ mt: 1 }}>
        <AddButton onClick={addNewFieldToGroup}>Add Field</AddButton>
      </Typography>}
    </Box>
    {closestEdge === "bottom" && <DragDropIndicator />}
  </Box>;
}