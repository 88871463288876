import HistoryIcon from '@mui/icons-material/History';
import RefreshIcon from '@mui/icons-material/Refresh';
import { IconButton, InputAdornment, TextField, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTabs } from '../../context/TabContext';
import { useUpdateOrderItemRedux } from '../../hooks/useUpdateOrderItemRedux';
import { useScopeCheck } from '../scopes';

QuantityField.propTypes = {
  row: PropTypes.object,
};

export default function QuantityField({ row }) {
  const hasAccess = useScopeCheck(['View-Order'], true);
  const updateOrderItemRedux = useUpdateOrderItemRedux();
  const [qty, setQty] = useState(row.quantity)

  const { activeTab } = useTabs();
  return (
    <>
      <TextField
        disabled={!hasAccess}
        size="small"
        value={qty || ''}
        id={`${activeTab.key}quantity-${row.id}`}
        onChange={(e) => {
          const newValue = Number(e.target.value);
          if (!Number.isNaN(newValue) && newValue >= 0) {
            setQty(newValue);
            if (row?.origQty === undefined) {
              updateOrderItemRedux(row.id, 'origQty', row.quantity);
            }
            updateOrderItemRedux(row.id, 'quantity', newValue);
          }
        }}
        InputProps={{
          style: { height: '30px', borderRadius: '4px' },
          startAdornment: (
            <InputAdornment position="start">
              {row?.origQty !== undefined && row?.origQty !== row.quantity && (
                <Tooltip title={row?.origQty} placement="top">
                  <IconButton
                    onClick={() => {
                      updateOrderItemRedux(row.id, 'quantity', row?.origQty);
                      setQty(row?.origQty);
                    }}
                    sx={{
                      padding: '0px',
                      margin: '0px',
                      height: '15px',
                      ml: '-5px',
                      width: '15px',
                    }}
                    color=""
                  >
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
              )}
            </InputAdornment>
          ),
        }}
      />
      {row?.originalQuantity !== undefined &&
        row?.originalQuantity !== row?.origQty &&
        row?.originalQuantity !== row.quantity && (
          <Tooltip title={`Revert back to ${row?.originalQuantity}`} placement="top" arrow>
            <IconButton
              onClick={() => {
                updateOrderItemRedux(row.id, 'quantity', row?.originalQuantity);
                setQty(row?.originalQuantity)
              }}
              sx={{ padding: '0px', margin: '0px', marginLeft: '5px' }}
              color={row.quantity === row?.originalQuantity ? '' : 'secondary'}
            >
              <HistoryIcon color="secondary" />
            </IconButton>
          </Tooltip>
        )}
    </>
  );
}
