import PropTypes from 'prop-types';
import { Autocomplete, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useSnackbar } from '../../../../../../reusable-components/snackbar';
import { getOrdersForTicket } from '../../../../../../api/tickets';

TicketOrderSearch.propTypes = { facilityId: PropTypes.number, onChange: PropTypes.func };

export default function TicketOrderSearch({ facilityId, onChange })
{
  const { enqueueSnackbar } = useSnackbar();

  const [orders, setOrders] = useState([]);
  const [orderSearch, setOrderSearch] = useState("");
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);

  useEffect(() => {
    setOrders([]);
    setSelectedOrder(null);
    setOrderSearch("");
    if (facilityId > 0) {
      getOrdersForTicket(facilityId)
        .then(res => setOrders(res.data))
        .catch(() => enqueueSnackbar('Failed to get orders', { variant: 'error' }));
    }
  }, [facilityId, enqueueSnackbar])

  useEffect(() => {
    setFilteredOrders(orders.filter(order => order.orderNumber.includes(orderSearch)).slice(0, 50));
  }, [orders, orderSearch])

  useEffect(() => { onChange(selectedOrder); }, [selectedOrder, onChange])

  return <Autocomplete
    size="small"
    fullWidth
    isOptionEqualToValue={(option, value) => option?.id === value?.id}
    options={filteredOrders.map((order) => ({
      id: order.id,
      label: `Order# ${order.orderNumber}`,
      orderNumber: order.orderNumber,
      department: order.department || 'N/A',
      submittedBy: order.submittedBy || 'N/A',
      onBehalfOf: order.personFor || 'N/A',
      approvedBy: order.approvedBy || 'N/A',
    }))}
    value={selectedOrder}
    renderInput={(params) => <TextField {...params} placeholder="Start typing your order #" />}
    onChange={(event, newValue) => setSelectedOrder(newValue)}
    onInputChange={(event, newValue) => setOrderSearch(newValue?.replace("Order# ", ""))}
    renderOption={(props, option) => (
      <li {...props}>
        <OptionWrapper>
          <strong>{option.label}</strong>
          <span>
            <span style={{ fontWeight: 'bold' }}>Department:</span>
            {` ${option.department}`}
          </span>
          <span>
            <span style={{ fontWeight: 'bold' }}>Submitted By:</span>
            {` ${option.submittedBy?.id}`}
          </span>
          <span>
            <span style={{ fontWeight: 'bold' }}>On Behalf Of:</span>
            {` ${option.onBehalfOf}`}
          </span>
          <span>
            <span style={{ fontWeight: 'bold' }}>Approved By:</span>
            {` ${option.approvedBy}`}
          </span>
        </OptionWrapper>
      </li>
    )}
  />;
}

const OptionWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
  backgroundColor: theme.palette.background.paper,
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  borderBottom: `1px solid #000`,
  borderTop: `1px solid #000`,
  padding: theme.spacing(1),
  width: '100%',
}));
