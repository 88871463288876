import { useState, useEffect, useMemo } from 'react';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import NotInterestedIcon from '@mui/icons-material/NotInterestedRounded';
import CustomDataGrid from '../../../reusable-components/datagrid/CustomDataGrid';
import { useSelector, useDispatch } from '../../../redux/store';
import {
  NEW_INVOICES,
  PENDING_INVOICES,
  COMPLETED_INVOICES,
} from '../../../reusable-components/datagrid/invoiceColumns';
import { ConfirmDialog } from '../../../reusable-components/confirm-dialog';
import {
  getPendingInvoicing,
  getPreviousInvoicing,
  getManualInvoicing,
  addInvoiceLog,
} from '../../../redux/slices/invoicing';
import { createColumns } from '../../../reusable-components/datagrid/custom-filters';
import { useSnackbar } from '../../../reusable-components/snackbar';
import InvoiceStatusModal from './InvoiceStatusModal';
import { ScopeGuard } from '../../../reusable-components/scopes';
import { deleteInvoice } from '../../../api/invoicing';
import { HOST_API_URL } from '../../../config-global';
import { fMonthDayYear } from '../../../utils/formatTime';
import ManagerChipsWrapper from '../../../reusable-components/datagrid/ManagerChipsWrapper';

InvoicingView.propTypes = {
  CustomToolbar: PropTypes.func,
  setActiveRows: PropTypes.func,
};
export default function InvoicingView({ CustomToolbar, setActiveRows }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const section = location.pathname.split('/').pop();

  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = useState([]);
  const {
    data: { invoiceData, pendingInvoicing, previousInvoicing, manualInvoicing },
  } = useSelector((state) => state.invoicing);

  const { user } = useSelector((state) => state.user);
  const { que } = invoiceData;

  const isLoading = useSelector((state) => state.invoicing.isLoading);
  useEffect(() => {
    if (section === 'new') {
      setData(manualInvoicing);
    } else if (section === 'pending') {
      setData(pendingInvoicing);
    } else if (section === 'pendingFilter') {
      setData(pendingInvoicing.filter((invoice) => invoice.facilityManagerAssignments.find((fma) => fma.currentUser)));
    } else {
      setData(previousInvoicing);
    }
  }, [pendingInvoicing, previousInvoicing, manualInvoicing, searchParams, section]);

  const handleDelete = async (id) => {
    const response = await deleteInvoice(id);
    if (response.status === 200) {
      enqueueSnackbar('Invoice deleted successfully', { variant: 'success' });
      if (section === 'new') {
        dispatch(getManualInvoicing());
      } else if (section === 'pending' || section === 'pendingFilter') {
        dispatch(getPendingInvoicing({ pending: true }));
      } else {
        dispatch(
          getPreviousInvoicing({
            startDate: searchParams.get('start'),
            endDate: searchParams.get('end'),
            pending: false,
          })
        );
      }
    }
  };
  const StatusButtons = (params) => {
    const { sent, alternateSent, invoiceBookedTo, total, deleteFlag, dateDeleted, deletedBy } = params.row;
    return (
      <>
        {section === 'previous' && (
          <>
            <Tooltip
              title={
                deleteFlag ? (
                  <>
                    <div>
                      Deleted by {deletedBy} on: {dateDeleted && fMonthDayYear(dateDeleted)}
                    </div>
                    <br />
                  </>
                ) : total === 0 ? (
                  <div>Total $0.00</div>
                ) : alternateSent ? (
                  <div>Booking vendor rejected invoice due to already having an invoice with that billing number</div>
                ) : !invoiceBookedTo ? (
                  <div>Sent to booking vendor</div>
                ) : (
                  <div>
                    {invoiceBookedTo?.sentTo ? <div>Sent To: {invoiceBookedTo?.sentTo}</div> : ''}
                    {invoiceBookedTo?.addedBy ? <div>Sent By: {invoiceBookedTo.addedBy}</div> : ''}
                    {invoiceBookedTo?.dateAdded ? <div>Sent On: {fMonthDayYear(invoiceBookedTo?.dateAdded)}</div> : ''}
                    {invoiceBookedTo?.sentAutomatically ? <div>Sent through the automatic invoice system</div> : ''}
                  </div>
                )
              }
            >
              {deleteFlag ? (
                <CancelIcon color={'error'} />
              ) : total !== 0 ? (
                <CheckCircleIcon color={sent && !alternateSent ? 'success' : alternateSent ? 'warning' : 'error'} />
              ) : total === 0 ? (
                <NotInterestedIcon color={'warning'} />
              ) : (
                ''
              )}
            </Tooltip>
            <InvoiceStatusModal row={params.row} />
          </>
        )}
      </>
    );
  };

  const status = {
    field: 'status',
    headerName: 'Status',
    width: 50,
    renderCell: (params) => StatusButtons(params),
    id: 'status',
    className: 'status',
    sortable: false,
  };

  const vendorInvoiceId = {
    field: 'vendorInvoiceId',
    headerName: 'Vendor Id',
    minWidth: 90,
    flex: 0.8,
    renderCell: (params) => {
      const isQueued = params.row.queInfo !== null;

      return isQueued ? (
        <>
          <Tooltip
            title={
              <>
                <div>Invoice in Que:</div>
                <div>Added by: {params.row.queInfo?.addedBy || ''}</div>
                <div>Added on: {fMonthDayYear(params.row.queInfo.dateAdded)}</div>
                <div>Que processes: {params.row.queInfo.queRunTime}</div>
                <div>Sends to: {params.row.queInfo.sendTo}</div>
              </>
            }
          >
            <Box
              component="div"
              sx={{
                wordWrap: 'break-word',
                whiteSpace: 'pre-wrap',
                fontSize: '0.75rem',
                color: 'info.main',
              }}
            >
              {params.value}
            </Box>
          </Tooltip>
        </>
      ) : (
        <Box
          component="div"
          sx={{
            wordWrap: 'break-word',
            whiteSpace: 'pre-wrap',
          }}
        >
          {params.value}
        </Box>
      );
    },
    type: 'customText',
    valueGetter: (params) => params.row.vendorInvoiceId,
  };

  const ActionButtons = (params) => {
    const { id } = params.row;
    return (
      <>
        <>
          <ScopeGuard scopes={['Invoice-Edit']} allowAdmin>
            <ConfirmDialog
              icon="delete-outline"
              title="Delete"
              color="error"
              content={`Are you sure you want to delete this invoice?`}
              actionButton="Delete"
              action={() => {
                handleDelete(id);
              }}
            />
          </ScopeGuard>
          {params.row.hasMainFile && (
            <>
              <Tooltip arrow title="View Main Invoice File">
                <IconButton
                  color="warning"
                  onClick={() => {
                    const modal = document.createElement('div');
                    modal.style.position = 'fixed';
                    modal.style.zIndex = '100000';
                    modal.style.left = '0';
                    modal.style.bottom = '0';
                    modal.style.width = '100%';
                    modal.style.height = '100%';
                    modal.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
                    modal.style.display = 'flex';
                    modal.style.justifyContent = 'center';
                    modal.style.alignItems = 'center';

                    const iframe = document.createElement('iframe');
                    iframe.style.width = '70%';
                    iframe.style.height = '100%';
                    iframe.src = `${HOST_API_URL}Api/Invoices/ViewInvoiceFile?id=${id}&mainFile=true`;

                    modal.appendChild(iframe);

                    modal.addEventListener('click', (e) => {
                      if (e.target === modal) {
                        document.body.removeChild(modal);
                      }
                    });
                    document.body.appendChild(modal);
                  }}
                >
                  <RemoveRedEyeIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
        </>
      </>
    );
  };

  const actions = {
    field: 'actions',
    headerName: 'Actions',
    width: 200,
    renderCell: (params) => ActionButtons(params),
    id: 'actions',
    className: 'actions',
    sortable: false,
  };

  const manager = ManagerChipsWrapper(() => dispatch(getPendingInvoicing({ pending: true })), 'Invoices');
  const conditionalColumns = useMemo(() => {
    if (section === 'new') {
      return createColumns([...NEW_INVOICES.slice(0, 11), vendorInvoiceId, ...NEW_INVOICES.slice(11)]);
    }
    if (section === 'pending' || section === 'pendingFilter') {
      return createColumns([...PENDING_INVOICES.slice(0, 11), vendorInvoiceId, ...PENDING_INVOICES.slice(11), manager]);
    }
    return createColumns([...COMPLETED_INVOICES, status]);
  }, [searchParams, section, que]);

  // const columns = createColumns([...conditionalColumns]);

  if (!invoiceData) return null;
  return (
    <>
      <Grid item xs={12}>
        <CustomDataGrid
          gridId="invoicing-invoice-view"
          applyQuickFilter
          boxSX={{ height: 'calc(100vh - 280px)' }}
          data={data}
          gridColumns={[...conditionalColumns, actions]}
          // sort={ sort is getting set when the data comes in
          CustomLeftToolbar={CustomToolbar}
          setActiveRows={setActiveRows}
          onCellClick={(row) => {
            const selection = window.getSelection().toString();
            if (selection.length > 0) return;
            if (row.field !== 'manager' && row.field !== 'actions' && row.field !== 'status') {
              const invoiceId = row.id;
              dispatch(
                addInvoiceLog({
                  invoiceId,
                  messageType: 'View',
                })
              );
              navigate(`/dashboard/invoicing/${section}/${invoiceId}`);
            }
          }}
          isLoading={!data || isLoading}
        />
      </Grid>
    </>
  );
}
