import { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Grid, IconButton, Button } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useDispatch, useSelector } from '../../../../redux/store';
import { ALL_PRODUCTS } from '../../../../reusable-components/datagrid/orderGuidesColumns';
import CustomToolbar from './CustomToolbar';
import {
  getOrderGuides,
  getOrderGuidesByFacilityAndCategory,
} from '../../../../redux/slices/orderGuides';
import { createColumns, CustomFilter } from '../../../../reusable-components/datagrid/custom-filters';
import { useFilterModel } from '../../../../hooks/useFilterModel';
import { useScopeCheck } from '../../../../reusable-components/scopes';
import { useTabs } from '../../../../context/TabContext';
import Iconify from '../../../../reusable-components/iconify';
import { SpinningLogo } from '../../../../reusable-components/logo';
import { SkeletonDataGrid } from '../../../../reusable-components/skeleton/SkeletonDataGrid';

export default function OrderGuide() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const hasEditAccess = useScopeCheck(['Edit-OrderGuide'], true);

  const gridStyle = {
    height: '38vh',
    width: '100%',
  };
  const { id = '' } = useParams();
  const [data, setData] = useState([]);
  const baseUrl = window.location.href.includes('&start')
    ? window.location.href.split('&start')[0]
    : window.location.href;
  const { activeTab } = useTabs();

  const sessionStorageKey = `tab-${activeTab.key}-filters-${baseUrl}`;
  const loadFiltersFromSession = () => {
    const savedFilters = sessionStorage.getItem(sessionStorageKey);
    return savedFilters ? JSON.parse(savedFilters) : {};
  };
  const [filters, setFilters] = useState(loadFiltersFromSession());
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeFilter, setActiveFilter] = useState(null);
  const [quickFilter, setQuickFilter] = useState('');
  const [tempOrderGuide, setTempOrderGuide] = useState([]);
  const [previousOrderGuide, setPreviousOrderGuide] = useState([]);

  const [currentOrderGuide, setCurrentOrderGuide] = useState(null);
  const {
    data: { orderGuideActivations, orderGuide, allProducts, templates },
    isLoading,
    isLoadingProducts,
  } = useSelector((state) => state.orderGuides);    

  useEffect(() => {
    setCurrentOrderGuide(
      orderGuideActivations.find((orderGuide) => orderGuide.id === parseInt(id, 10)) ||
        templates?.find((template) => template.id === parseInt(id, 10))
    );
  }, [orderGuideActivations, id, templates]);

  useEffect(() => {
    setTempOrderGuide(orderGuide.orderGuide);
    setPreviousOrderGuide(orderGuide.orderGuide);
    setData(filterForPreferredVendor());
  }, [orderGuide, allProducts]);

  const filterForPreferredVendor = () => {
    const restrictedLookup = orderGuide.preferences.filter(preference => preference.isRestricted).reduce((acc, curr) => {
      acc[`${curr.vendorId}-${curr.agoraSubcategoryId}`] = curr;
      return acc;
    }, {});

    const preferencesLookup = orderGuide.preferences.filter(preference => !preference.isRestricted).reduce((acc, curr) => {
      acc[`${curr.vendorId}-${curr.agoraSubcategoryId}`] = curr;
      return acc;
    }, {});

    return allProducts.flatMap((product) => {
      const { preferredProducts, productDetails } = product.productDetails.reduce((result, productDetail) => {
          const key = `${productDetail.vendorId}-${product.agoraSubcategory.id}`;
          if (restrictedLookup[key]) return result;
          if (preferencesLookup[key]) result.preferredProducts.push(productDetail);
          else result.productDetails.push(productDetail);
          return result;
        }, { preferredProducts: [], productDetails: [] });

      if (preferredProducts.length === 0 && productDetails.length === 0) return [];

      const sortedProductDetails = (preferredProducts.length > 0 ? preferredProducts : productDetails)
        .sort((a, b) => a.price / a.uomAmount - b.price / b.uomAmount);

      return {
        ...product,
        productDetails: sortedProductDetails,
      };
    });
  }

  useEffect(() => {
    if (!orderGuideActivations?.length) {
      dispatch(getOrderGuides());
    } else {
      dispatch(getOrderGuidesByFacilityAndCategory(id));
    }
  }, [id, orderGuideActivations]);

  const filteredData = useMemo(() => {
    const orderGuideLookup = tempOrderGuide?.reduce((acc, curr) => { acc[`${curr.productId}`] = curr; return acc; }, {}) || {};
    const detailLookup = data.flatMap(product => [...product.productDetails]).reduce((acc, curr) => { acc[`${curr.id}`] = curr; return acc; }, {});

    const updatedData = data.map((item) => {
      const tempOrderGuideId = orderGuideLookup[item.id];
      return {
        ...item,
        activeProductDetail: tempOrderGuideId ? detailLookup[tempOrderGuideId.itemId] : null,
        activeTempOrderGuide: tempOrderGuideId,
      };
    });
    const activeData = updatedData.filter((item) => item.activeProductDetail);
    const unactiveData = updatedData
      .filter((item) => !item.activeProductDetail)
      .map((item) => ({ ...item, activeProductDetail: item.productDetails[0] }));
    return { activeData, unactiveData };
  }, [data, tempOrderGuide]);

  const ActionCell = ({ id, params }) => {
    const cellIsAvailable = (id, tempOrderGuide) => {
      const action = tempOrderGuide?.find((item) => item.productId === id);
      if (action) {
        return (
          <IconButton
            disabled={!hasEditAccess}
            onClick={() => {
              const newOrderGuide = tempOrderGuide?.filter((item) => item.productId !== id);
              setTempOrderGuide(newOrderGuide);
            }}
            sx={{
              opacity: 0.8,
            }}
          >
            <HighlightOffIcon color="error" />
          </IconButton>
        );
      }
      return (
        <IconButton
          disabled={!hasEditAccess}
          onClick={() => {
            const newOrderGuide = [...tempOrderGuide, { productId: id, itemId: params.productDetails[0].id }];
            setTempOrderGuide(newOrderGuide);
          }}
        >
          <CheckCircleOutlineIcon color="success" />
        </IconButton>
      );
    };
    return cellIsAvailable(id, tempOrderGuide);
  };

  const action = {
    field: 'action',
    headerName: 'Action',
    minWidth: 50,
    flex: 0.3,
    renderCell: (params) => <ActionCell id={params.row.id} params={params.row} />,
  };
  function getValueByPath(obj, path) {
    return path.split('.').reduce((o, p) => (o || {})[p], obj);
  }
  const fieldValues = useMemo(() => {
    const values = {};

    ALL_PRODUCTS.forEach((col) => {
      if (col?.type === 'customSelect' && data) {
        if (col.path) {
          values[col.field] = Array.from(new Set(data.map((row) => getValueByPath(row, col.path)))).map((value) => ({
            value,
          }));
        } else
          values[col.field] = Array.from(new Set(data.map((row) => row[col.field])))
            .filter((value) => value !== undefined && value !== null && value !== '')
            .map((value) => ({ value }));
      }
    });

    return values;
  }, [data]);

  const columns = createColumns([...ALL_PRODUCTS, action], filters, setAnchorEl, setActiveFilter);
  const filterModel = useFilterModel(filters);

  const filterRows = (rows, quickFilter) => {
    if (!quickFilter) return rows;
    const normalizeString = (str) => str?.replace(/[^a-z0-9\s]/gi, '')?.toLowerCase();
    const quickFilterWords = normalizeString(quickFilter).split(/\s+/);
    if (!quickFilterWords.length) return rows;
    return rows.filter((row) =>
      quickFilterWords.every((word) =>
        columns.some((col) => {
          const value = col?.valueGetter ? col.valueGetter({ row }) : row[col.field];
          if (Array.isArray(value)) {
            return value.some((element) => normalizeString(String(element))?.includes(word));
          }
          return normalizeString(String(value))?.includes(word);
        })
      )
    );
  };

  if (!data?.length && !tempOrderGuide?.length)
    return (
      <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ mt: 5 }}>
        <Box
          sx={{
            zIndex: 999999999999999,
            height: '100%',
            minHeight: '60vh',
          }}
        >
          <SpinningLogo />
        </Box>
      </Grid>
    );

  const dataByCategory = filteredData.unactiveData.filter(
    (item) =>
      item?.agoraCategory?.id === currentOrderGuide?.agoraCategoryId &&
      !tempOrderGuide?.find((og) => og.productId === item.id) //item isnt in the temp order guide
  );

  return (
    <>
      <Grid item xs={12}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Button
              variant="text"
              onClick={() => {
                navigate(`/dashboard/admin/order-guides`);
              }}
              startIcon={<Iconify icon="mingcute:left-fill" width={24} height={24} sx={{ mx: -1 }} />}
              sx={{
                color: '#454F5B',
                fontWeight: 'bold',
              }}
            >
              Back to order guides
            </Button>
          </Grid>
        </Grid>
        <CustomToolbar
          setFilters={setFilters}
          setActiveFilter={setActiveFilter}
          setData={setData}
          setTempOrderGuide={setTempOrderGuide}
          previousOrderGuide={previousOrderGuide}
          tempOrderGuide={tempOrderGuide}
          loading={isLoading}
          setQuickFilter={setQuickFilter}
          filteredData={filteredData}
        />
        <Box sx={gridStyle}>
          <DataGridPro
            getRowHeight={() => 'auto'}
            rows={filterRows(filteredData.activeData, quickFilter, columns)}
            getRowId={(row) => row.id}
            columns={columns}
            disableSelectionOnClick
            rowHeight={44}
            loading={isLoading || isLoadingProducts}
            components={{ LoadingOverlay: SkeletonDataGrid }}
            filterModel={filterModel}
            disableColumnMenu
            disableColumnFilter
            disableColumnSelector
            disableColumnPinning
            disableColumnReorder
            sx={{
              '& .MuiDataGrid-columnHeaders': {
                position: 'sticky',
                zIndex: 999,
                backgroundColor: '#C053C9 !important',
              },
            }}
          />
        </Box>
        <Box sx={{ ...gridStyle }}>
          <DataGridPro
            getRowHeight={() => 'auto'}
            rows={filterRows(dataByCategory, quickFilter, columns)}
            columns={columns}
            disableSelectionOnClick
            rowHeight={44}
            loading={isLoading || isLoadingProducts}
            components={{ LoadingOverlay: SkeletonDataGrid }}
            filterModel={filterModel}
            disableColumnMenu
            disableColumnFilter
            disableColumnSelector
            disableColumnPinning
            disableColumnReorder
          />
        </Box>
        <CustomFilter
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          setActiveFilter={setActiveFilter}
          activeFilter={activeFilter}
          filters={filters}
          setFilters={setFilters}
          fieldValues={fieldValues}
          columns={columns}
        />
      </Grid>
    </>
  );
}
