import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogTitle, DialogContent, IconButton, DialogActions, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useSelector, useDispatch } from '../../../../../redux/store';
import Iconify from '../../../../../reusable-components/iconify';
import { postAddProductImage, updateProductFormData, duplicateItemData } from '../../../../../api/catalog';
import EditItem from './EditItem';
import { getProductDetails } from '../../../../../redux/slices/catalog';
import { useSnackbar } from '../../../../../reusable-components/snackbar';
import { AwaitButton } from '../../../../../reusable-components/await-button';

EditItemDialog.propTypes = {
  data: PropTypes.array,
  id: PropTypes.number,
  pageId: PropTypes.string,
  vendorItemId: PropTypes.string,
  productId: PropTypes.number,
  description: PropTypes.string,
  agoraCategoryId: PropTypes.number,
  agoraSubcategoryId: PropTypes.number,
  notes: PropTypes.string,
  categories: PropTypes.array,
  editParent: PropTypes.bool,
  row: PropTypes.object,
  isWarehouseItem: PropTypes.bool,
};

export default function EditItemDialog({
  editParent,
  data,
  id,
  description,
  productId,
  notes,
  agoraCategoryId,
  agoraSubcategoryId,
  categories,
  row,
  isWarehouseItem = false,
}) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [allItems, setAllItems] = useState();
  const [disabled, setDisabled] = useState(false);
  const [ppdOptions, setPpdOptions] = useState([]);
  const { orderById } = useSelector((state) => state.orders || {});
  const { unsavedChanges } = orderById[id] || {};
  const { enqueueSnackbar } = useSnackbar();

  const checkDuplicateItem = async (vendorId, vendorItemId, itemId) => {
    const response = await duplicateItemData(vendorId, vendorItemId, itemId);
    if (response.status === 200) {
      if (response.data === true) {
        enqueueSnackbar('Item already exists with same vendor and vendor id', { variant: 'error' });
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    if (data) {
      setAllItems(data);
      if (!isWarehouseItem) setPpdOptions(data.filter((item) => item.id === id)[0]?.ppdFacilities || []);
    }
  }, [data]);

  useEffect(() => {
    if (!allItems?.length) return;

    const selectedItem = allItems.find((item) => item.id === id);

    const isEmptyOrNull = (value) => value === undefined || value === null || value === '';
    const isEmptyOrNullOrZero = (value) => value === undefined || value === null || value === '' || value === 0 || value === '0';

    const checkFieldsForEmptyValues = (obj, fields) => fields.some((field) => (field === 'price' ? isEmptyOrNull(obj[field]) : isEmptyOrNullOrZero(obj[field])));

    if (!selectedItem) {
      setDisabled(true);
    } else {
      const mainFields = ['price', 'vendor', 'vendorId', 'uomAmount', 'uomType'];
      const uomFields = ['price', 'uomAmount', 'uomType'];

      const isDisabled =
        checkFieldsForEmptyValues(selectedItem, mainFields) ||
        (selectedItem.productDetailUOM && checkFieldsForEmptyValues(selectedItem.productDetailUOM, uomFields));
      setDisabled(isDisabled);
    }
  }, [allItems, id]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const urlPattern = /^(https?:\/\/|ftps?:\/\/)/;
  const saveProduct = async () => {
    const isDuplicate = await checkDuplicateItem(
      allItems.filter((item) => item.id === id)[0]?.vendorId,
      allItems.filter((item) => item.id === id)[0]?.vendorItemId,
      allItems.filter((item) => item.id === id)[0]?.id
    );

    if (isDuplicate) return;
    let picUrls = allItems.filter((item) => item.id === id)[0]?.pictures;
    const processedUrls = await Promise.all(
      picUrls.map(async (url) => {
        if (!url.includes('//res.cloudinary.com/agoraserv') && urlPattern.test(url)) {
          const imgUrl = await postAddProductImage(url);
          return imgUrl.data.length ? imgUrl.data : null;
        }
        return url;
      })
    );
    picUrls = processedUrls.filter((url) => url !== null);

    const updatedItems = isWarehouseItem
      ? (prev) =>
          prev.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                pictures: picUrls,
              };
            }
            return item;
          })
      : allItems.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              ppdFacilities: ppdOptions,
              pictures: picUrls,
            };
          }
          return item;
        });

    const response = await updateProductFormData({
      description,
      productId,
      allItems: updatedItems,
      notes,
      agoraCategoryId,
      agoraSubcategoryId,
      categories,
    });
    if (response.status === 200) {
      if (row !== undefined) {
        dispatch(getProductDetails(row.productId, false));
      } else dispatch(getProductDetails(id, false));
      enqueueSnackbar('Item updated successfully', { variant: 'success' });
    }
    else { enqueueSnackbar('Error Updating Item', { variant: 'error' }); }
   
    setOpen(false);
  };
  return (
    <div>
      <Tooltip title="Edit">
        <IconButton color="secondary" onClick={handleClickOpen} disabled={editParent || unsavedChanges}>
          <EditIcon />
        </IconButton>
      </Tooltip>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle
          sx={{
            textAlign: 'center',
          }}
        >
          Edit item
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ width: '100%' }}>
          <EditItem
            allItems={allItems}
            setAllItems={setAllItems}
            id={id}
            ppdOptions={ppdOptions}
            setPpdOptions={setPpdOptions}
            isWarehouseItem={isWarehouseItem}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', mb: 2 }}>
          <AwaitButton
            variant="contained"
            color="primary"
            sx={{ width: '200px', boxShadow: 3, fontWeight: 'bold' }}
            onClick={saveProduct}
            disabled={disabled}
          >
            Save
          </AwaitButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
