import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  DialogContentText,
  Alert,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { postVendorLinkForm, getIntegrationsForVendor } from '../../../../api/warehouse-vendors';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { AwaitButton } from '../../../../reusable-components/await-button';
import CloseIconButton from '../../../../reusable-components/basic-buttons/CloseIconButton';

WarehouseVendorLinkModal.propTypes = {
  onClose: PropTypes.func,
  row: PropTypes.object,
  data: PropTypes.object,
  fetchData: PropTypes.func,
  open: PropTypes.bool,
};

export default function WarehouseVendorLinkModal({ onClose, row, data, fetchData, open }) {
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = useState({});
  const [integrations, setIntegrations] = useState(null);

  useEffect(() => {
    setValue({
      id: row?.id,
      siteId: row?.siteId,
      siteVendorId: row?.siteVendorId,
      warehouseVendorId: row?.warehouseVendorId,
      cxmlConfigId: row?.cxmlConfigId,
      punchoutConfigId: row?.punchoutConfigId,
    });
    if (row) getIntegrations(row.warehouseVendorId);
  }, [row]);

  const handleSave = async () => {
    const response = await postVendorLinkForm(value);
    if (response.data) {
      enqueueSnackbar(`Warehouse vendor link ${row ? 'updated' : 'added'} successfully`, { variant: 'success' });
      onClose();
    } else {
      enqueueSnackbar(`Failed to ${row ? 'update' : 'add'} warehouse vendor link`, { variant: 'error' });
    }
    fetchData();
  };

  const siteList = data?.sites || [];
  const vendorList = data?.vendorList?.map((v) => ({ label: v?.name, value: v?.id })) || [];
  const siteVendorList =
    data?.siteVendors
      .filter((sv) => sv.siteId === value.siteId)
      ?.map((vendor) => ({
        label: vendor?.vendorName,
        value: vendor?.vendorId,
      })) || [];

  const autoCompleteBaseOps = {
    fullWidth: true,
    size: 'small',
    isOptionEqualToValue: (option, value) => option?.value === value?.value,
    renderInput: (params) => <TextField {...params} label="Search or select" />,
    sx: {
      mt: 0.5,
      '& legend': { display: 'none' },
      '& fieldset': { top: 0 },
      '& .MuiFormLabel-root ': { display: 'none' },
    },
  };

  const duplicateLink = data?.links.some(
    (x) =>
      x.siteId === value.siteId &&
      x.id !== row?.id &&
      (x.warehouseVendorId === value.warehouseVendorId || x.siteVendorId === value.siteVendorId)
  );

  const getIntegrations = async (warehouseVendorId) => {
    if (!warehouseVendorId) {
      setIntegrations(null);
      return;
    }
    const response = await getIntegrationsForVendor(warehouseVendorId);
    if (response.status === 200) setIntegrations(response.data);
    else {
      enqueueSnackbar(`Failed to fetch vendor integrations`, { variant: 'error' });
      setIntegrations(null);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ textAlign: 'center' }}>
        {row ? 'Edit' : 'Add'} Warehouse Link
        <CloseIconButton onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Warehouse Vendor *</DialogContentText>
        <Autocomplete
          {...autoCompleteBaseOps}
          value={vendorList.find((opt) => opt.value === value.warehouseVendorId) ?? null}
          onChange={(event, newValue) => {
            setValue({ ...value, warehouseVendorId: newValue?.value });
            getIntegrations(newValue?.value);
          }}
          options={vendorList}
        />
        <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Site *</DialogContentText>
        <Autocomplete
          {...autoCompleteBaseOps}
          value={siteList.find((opt) => opt.value === value.siteId) ?? null}
          onChange={(event, newValue) => setValue({ ...value, siteId: newValue?.value, siteVendorId: null })}
          options={data?.sites || []}
        />
        <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Site Vendor *</DialogContentText>
        <Autocomplete
          {...autoCompleteBaseOps}
          value={siteVendorList.find((opt) => opt.value === value.siteVendorId) ?? null}
          onChange={(event, newValue) => {
            setValue({ ...value, siteVendorId: newValue?.value });
          }}
          options={siteVendorList}
        />
        {integrations?.cxmlOrderConfigs.length > 0 && (
          <>
            <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>cXML Order Integration</DialogContentText>
            <Autocomplete
              {...autoCompleteBaseOps}
              value={integrations?.cxmlOrderConfigs.find((opt) => opt.value === value.cxmlConfigId) ?? null}
              onChange={(event, newValue) => {
                setValue({ ...value, cxmlConfigId: newValue?.value });
              }}
              options={integrations?.cxmlOrderConfigs}
            />
          </>
        )}
        {integrations?.punchoutConfigs.length > 0 && (
          <>
            <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Punchout Integration</DialogContentText>
            <Autocomplete
              {...autoCompleteBaseOps}
              value={integrations?.punchoutConfigs.find((opt) => opt.value === value.punchoutConfigId) ?? null}
              onChange={(event, newValue) => {
                setValue({ ...value, punchoutConfigId: newValue?.value });
              }}
              options={integrations?.punchoutConfigs}
            />
          </>
        )}
      </DialogContent>

      {duplicateLink && <Alert severity="error">Duplicate vendor link</Alert>}
      <DialogActions>
        <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={onClose}>
          Cancel
        </Button>
        <AwaitButton
          variant="contained"
          color="primary"
          size="medium"
          sx={{ width: 150 }}
          onClick={handleSave}
          disabled={!value.siteId || !value.siteVendorId || !value.warehouseVendorId || duplicateLink}
        >
          {row ? 'Save' : 'Add Link'}
        </AwaitButton>
      </DialogActions>
    </Dialog>
  );
}
