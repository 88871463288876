import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import CustomDataGrid from '../../../reusable-components/datagrid/CustomDataGrid';
import { RENTAL_VENDORS } from '../../../reusable-components/datagrid/rentalColumns';
import { getVendorData } from '../../../api/rentals';

const RentalVendors = () => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [rows, setRows] = useState([]);

  const fetchData = async () => {
    try {
      setDataLoaded(false);
      const response = await getVendorData();
      setRows(response);
      setDataLoaded(true);
    } catch (error) {
      console.error('Error fetching vendor data:', error);
      setDataLoaded(true);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Rentals Vendors | Platform Solutions</title>
      </Helmet>

      <CustomDataGrid
        gridId="rental-vendors"
        boxSX={{ width: '100%', height: 'calc(100vh - 260px)' }}
        data={rows}
        gridColumns={RENTAL_VENDORS}
        isLoading={!dataLoaded}
        sort={[{ field: 'name', sort: 'asc' }]}
      />
    </>
  );
};

export default RentalVendors;
