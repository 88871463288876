import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSnackbar } from '../../../../reusable-components/snackbar';

ShippingIcon.propTypes = {
  row: PropTypes.object,
};

export default function ShippingIcon({ row }) {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const itemTrackingLinks = (row) =>
    <>
      {row?.itemShips?.filter(itemShip => itemShip.trackingUrl)
        .flatMap((itemShip, index) => (
          itemShip.trackingNumber?.split(", ").map(trackingNumber =>
            <a key={index} href={itemShip.trackingUrl + trackingNumber} target="_blank" rel="noreferrer" style={{ color: '#fff' }}>
              {trackingNumber}
            </a>
          )))}
      {row?.itemShips?.filter(itemShip => !itemShip.trackingUrl)
        .flatMap((itemShip, index) => (itemShip.trackingNumber?.split(", ").map(trackingNumber => (
          <Grid container direction="row" justifyContent="center" alignItems="center"
            sx={{ '&:hover': { cursor: 'copy' } }}
            key={index}
            onClick={() => { navigator.clipboard.writeText(trackingNumber); enqueueSnackbar(`Copied ${trackingNumber} to clipboard`); }}>
            <Typography variant="caption">  {trackingNumber} {' '}</Typography>
            <IconButton>
              <ContentCopyIcon sx={{ color: "secondary.main", fontSize: 14 }} />
            </IconButton>
          </Grid>
        ))))}
    </>;


  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip title={<Box sx={{ m: 1 }}> {itemTrackingLinks(row)}</Box>}
          arrow
          PopperProps={{ disablePortal: true }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener>
          <IconButton edge="end" onClick={handleTooltipOpen}>
            <LocalShippingIcon sx={{ ml: 0.5 }} color="secondary" width={20} height={20} />

          </IconButton>
        </Tooltip >
      </div>
    </ClickAwayListener>
  )
}
