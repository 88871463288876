import { Box, Tooltip } from '@mui/material';
import { useState } from 'react';
import PropTypes from 'prop-types';
import Iconify from '../iconify';
import Image from '../image';
import Lightbox from '../lightbox';
import { renderColorBox } from '../../utils/cssStyles';
import { fCurrency } from '../../utils/formatNumber';
import { fDateTime } from '../../utils/formatTime';

const renderIcon = (value, icon) => (
  <Box>
    <Iconify icon={icon} width={13} height={13} /> {value}
  </Box>
);
export default function RenderImage({ params }) {
  RenderImage.propTypes = {
    params: PropTypes.object,
  };
  const [selectedImage, setSelectedImage] = useState(-1);
  const handleOpenLightbox = () => {
    setSelectedImage(0);
  };
  const handleCloseLightbox = () => {
    setSelectedImage(-1);
  };
  return (
    <>
      <Image
        alt="attachment"
        src={params.value}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            opacity: 0.9,
          },
        }}
        onClick={() => handleOpenLightbox()}
      />
      <Lightbox index={0} slides={[params.value]} open={selectedImage >= 0} close={handleCloseLightbox} />
    </>
  );
}

//image
const image = {
  field: 'image',
  headerName: 'Image',
  flex: 0.4,
  renderCell: (params) => <RenderImage params={params} />,
  sortable: false,
  filterable: false,
};
//description
const description = {
  field: 'description',
  headerName: 'Description',
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),
  type: 'customText',
};
//category
const category = {
  field: 'department',
  headerName: 'Category',
  minWidth: 110,
  flex: 0.75,
  renderCell: (params) => renderColorBox(params.value, 'secondary'),
  valueGetter: (params) => params.row.department,
  type: 'customSelect',
};
//subcategory
const subcategory = {
  field: 'subcategory',
  headerName: 'Subcategory',
  minWidth: 110,
  flex: 0.75,
  renderCell: (params) => renderColorBox(params.value, 'secondary'),
  valueGetter: (params) => params.row.subcategory,
  type: 'customSelect',
};
//vendor
const vendor = {
  field: 'vendor',
  headerName: 'Vendor',
  flex: 1,
  minWidth: 100,
  type: 'customSelect',
};
//itemId
const itemId = {
  field: 'itemId',
  headerName: 'PSR#',
  flex: 0.5,
  minWidth: 70,
  type: 'customText',
  renderCell: (params) => `PSR${params.row.itemId}`,
  valueGetter: (params) => `PSR${params.row.itemId}`,
};
//price
const price = {
  field: 'price',
  headerName: 'Price',
  minWidth: 80,
  flex: 1,
  renderCell: (params) => fCurrency(params.value),
};
//name
const name = {
  field: 'name',
  headerName: 'Name',
  minWidth: 100,
  flex: 1,
};
//emailTo
const emailTo = {
  field: 'emailTo',
  headerName: 'EmailTo',
  minWidth: 120,
  flex: 1,
};
//address
const address = {
  field: 'address',
  headerName: 'Address',
  minWidth: 80,
  flex: 1,
};
//city
const city = {
  field: 'city',
  headerName: 'City',
  minWidth: 80,
  flex: 1,
  type: 'customSelect',
};
//state
const state = {
  field: 'state',
  headerName: 'State',
  minWidth: 40,
  flex: 0.3,
  type: 'customSelect',
};
//zip
const zip = {
  field: 'zip',
  headerName: 'Zip',
  minWidth: 70,
  flex: 1,
};
//zip
const phone = {
  field: 'phone',
  headerName: 'Phone',
  minWidth: 60,
  flex: 1,
};
//orderNumber
const orderNumber = {
  field: 'orderNumber',
  headerName: 'Order Number',
  width: 90,
  flex: 1,
  type: 'customText',
  renderCell: (params) => {
    const isRejected = params.row.approvedBy == null && params.row.orderStatusId === 3;
    return (
      <Tooltip title={params.row.approvedBy == null ? 'Rental Rejected' : ''}>
        <span style={{ color: isRejected ? 'red' : 'inherit' }}>{params.value}</span>{' '}
      </Tooltip>
    );
  },
};
//facilityName
const facilityName = {
  field: 'facilityName',
  headerName: 'Facility Name',
  width: 90,
  flex: 1,
  type: 'customText',
};
//personFor
const personFor = {
  field: 'personFor',
  headerName: 'Person For',
  width: 90,
  flex: 1,
  type: 'customText',
};
//department
const department = {
  field: 'department',
  headerName: 'Department',
  minWidth: 110,
  flex: 0.75,
  renderCell: (params) => renderColorBox(params.value, 'secondary'),
  valueGetter: (params) => params.row.department,
  type: 'customSelect',
};
//onBehalfOf
const onBehalfOf = {
  field: 'onBehalfOf',
  headerName: 'On Behalf Of',
  width: 90,
  flex: 1,
  type: 'customText',
};
//submittedBy
const submittedBy = {
  field: 'submittedBy',
  headerName: 'Submitted By',
  minWidth: 120,
  flex: 1,
  type: 'customSelect',
};
const submittedOn = {
  field: 'submittedOn',
  headerName: 'Submitted On',
  type: 'customDate',
  minWidth: 100,
  flex: 1.2,
  renderCell: (params) => renderIcon(fDateTime(params.row.submittedOn), 'material-symbols:calendar-today-outline'),
};
const orderedQty = {
  field: 'orderedQty',
  headerName: 'Qty',
  renderCell: (params) => params.value > 0 && params.value,
  flex: 0.5,
};
const total = {
  field: 'total',
  headerName: 'Total',
  minWidth: 70,
  flex: 0.5,
  renderCell: (params) => fCurrency(params.value),
};
const approvedOn = {
  field: 'approvedOn',
  headerName: 'Approved On',
  type: 'customDate',
  minWidth: 100,
  flex: 1.2,
  renderCell: (params) => renderIcon(fDateTime(params.row.approvedOn), 'material-symbols:calendar-today-outline'),
};

const RENTAL_ORDER_ITEMS = [image, description, category, subcategory, itemId, price, vendor, total, orderedQty];

const RENTAL_VENDORS = [name, emailTo, address, city, state, zip, phone];

const PENDING_RENTALS = [
  orderNumber,
  facilityName,
  personFor,
  department,
  onBehalfOf,
  submittedBy,
  submittedOn,
  orderedQty,
  total,
];
const CURRENT_RENTALS = [
  orderNumber,
  facilityName,
  personFor,
  department,
  onBehalfOf,
  submittedBy,
  approvedOn,
  orderedQty,
  total,
];
const PREVIOUS_RENTALS = [
  orderNumber,
  facilityName,
  personFor,
  department,
  onBehalfOf,
  submittedBy,
  submittedOn,
  approvedOn,
  orderedQty,
  total,
];
export { RENTAL_ORDER_ITEMS, RENTAL_VENDORS, PENDING_RENTALS, CURRENT_RENTALS, PREVIOUS_RENTALS };
