import { Helmet } from 'react-helmet-async';
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Grid, Divider } from '@mui/material';
import { useSelector, useDispatch } from '../../../../redux/store';
import RentalItem from './RentalItem';
import Scrollbar from '../../../../reusable-components/scrollbar';
import { getRentalItems } from '../../../../redux/slices/rentals';

export default function RentalItemsSelect() {
  const dispatch = useDispatch();
  const {
    data: { items, categoryData },
  } = useSelector((state) => state.rentals);

  const [showEditToCart, setShowEditToCart] = useState(false);
  const [searchParams] = useSearchParams();
  const selectedCategoryName = searchParams.get('category');
  const selectedSubcategoryName = searchParams.get('subcategory');

  // Find the categoryId for the selectedCategoryName
  const selectedCategory = categoryData.find((cat) => cat.categoryName === selectedCategoryName);
  const selectedCategoryId = selectedCategory?.categoryId;

  // Find the subcategoryId for the selectedSubcategoryName
  const selectedSubcategory = categoryData.find(
    (subcat) => subcat.categoryName === selectedCategoryName && subcat.subcategoryName === selectedSubcategoryName
  );
  const selectedSubcategoryId = selectedSubcategory?.subcategoryId;

  // Filter the items based on categoryId and subcategoryId
  const filteredItems = items.filter(
    (item) => item.categoryId === selectedCategoryId && item.subcategoryId === selectedSubcategoryId
  );
  useEffect(() => {}, [showEditToCart, setShowEditToCart]);

  useEffect(() => {
    dispatch(getRentalItems());
  }, [dispatch]);
  return (
    <>
      <Helmet>
        <title>Rental Items | Platform Solutions</title>
      </Helmet>

      <div style={{ width: '100%' }}>
        <header>
          <Divider
            flexItem
            sx={{
              height: '2px',
              opacity: 0.39,
              backgroundColor: '#4ca5e7',
              ml: 2,
              mb: 2,
              mt: 2,
            }}
          />
        </header>
      </div>

      {/* Display items based on the selected category */}
      <Scrollbar sx={{ height: 'calc(100vh - 300px)' }}>
        <Grid container spacing={4} sx={{ justifyContent: 'center', alignItems: 'flex-start' }}>
          {(filteredItems.length > 0 ? filteredItems : items).map((item) => (
            <RentalItem key={item.id} item={item} />
          ))}{' '}
        </Grid>
      </Scrollbar>
    </>
  );
}
