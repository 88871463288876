import { React, useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { Document, Page } from 'react-pdf';
import { Accordion, AccordionSummary, AccordionDetails, Box, Grid, IconButton, Typography, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDropzone } from 'react-dropzone';
import Iconify from '../../../../reusable-components/iconify';
import { useDispatch, useSelector } from '../../../../redux/store';
import { HOST_API_URL } from '../../../../config-global';
import PrintDialogModal from '../../../../reusable-components/pdf-viewer/PrintDialogModal';
import Scrollbar from '../../../../reusable-components/scrollbar';
import { renderInvoiceFile, getInvoiceFiles, addInvoiceFile } from '../../../../redux/slices/invoicing';
import { generateInvoicePdfFromData } from '../../../../api/invoicing';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import PDFViewer from '../../../../reusable-components/pdf-viewer/PDFViewer';
import { SpinningLogo } from '../../../../reusable-components/logo';
import { useScopeCheck } from '../../../../reusable-components/scopes';

InvoicePdfAttachment.propTypes = {
  selectedFileId: PropTypes.number,
};
export default function InvoicePdfAttachment({ selectedFileId }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    error,
    data: { invoicePhysicalAttachment, invoicePhysicalFile, invoiceFiles },
  } = useSelector((state) => state.invoicing);
  const { enqueueSnackbar } = useSnackbar();
  const hasEditAccess = useScopeCheck(['Invoice-Edit'], true);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: openDropzone,
  } = useDropzone({
    accept: {
      'application/pdf': [],
    },
    onDrop: (acceptedFiles) => {
      if (acceptedFiles[0] === undefined) return;
      addFile(acceptedFiles[0]);
    },
  });

  const [dataLoaded, setDataLoaded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [invoiceErrorFlag, setInvoiceErrorFlag] = useState(false);
  const [attachmentErrorFlag, setAttachmentErrorFlag] = useState(false);
  const [displayMainFile, setDisplayMainFile] = useState(false);
  //render main file
  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(renderInvoiceFile(id, true));
      setDataLoaded(true);
      setInvoiceErrorFlag(response !== 'success');
      setAttachmentErrorFlag(false);
      setDisplayMainFile(true);
    };
    fetchData();
  }, [invoiceFiles, id]);
  //render attachment file
  useEffect(() => {
    async function fetchData() {
      const response = await dispatch(renderInvoiceFile(selectedFileId, false));
      setInvoiceErrorFlag(false);
      setAttachmentErrorFlag(response !== 'success');
      setDisplayMainFile(false);
    }
    if (selectedFileId !== 0) fetchData();
    else setAttachmentErrorFlag(true);
  }, [selectedFileId]);

  const generatePDF = async (e) => {
    e.stopPropagation();
    setIsSubmitting(true);
    try {
      const response = await generateInvoicePdfFromData(id);
      if (response.status === 200) {
        enqueueSnackbar('PDF generated successfully', { variant: 'success' });
        await dispatch(getInvoiceFiles(id));
      }
    } catch (error) {
      enqueueSnackbar('PDF generation failed', { variant: 'error' });
    }

    setIsSubmitting(false);
  };

  const addFile = async (file) => {
    const response = await dispatch(addInvoiceFile(id, file));
    if (response === 'success') {
      enqueueSnackbar('File added successfully', { variant: 'success' });
    }
  };

  return (
    <>
      <>
        {/* <Box sx={accordionBoxStyle}>
          <Accordion
            expanded={openAttachment}
            sx={accordionStyle}
            onChange={(e, newValue) => setOpenAttachment(newValue)}
          >
            <AccordionSummary
              sx={{
                color: 'info.main',
              }}
              expandIcon={<Iconify icon="ic:outline-expand-more" color="info" height="24px" width="24px" />}
            >
              <Grid container alignItems={'center'}>
                <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }}> Attachment &nbsp;</Typography>
              </Grid>
            </AccordionSummary>
            <AccordionDetails> */}
        <Box>
          {dataLoaded ? (
            <div>
              {displayMainFile && invoiceErrorFlag && (
                <Box
                  sx={{
                    border: 'dashed 1px #4ca5e7',
                    height: '400px',
                    cursor: 'pointer',
                    mt: '10px',
                  }}
                  {...getRootProps()}
                  onClick={() => {
                    if (hasEditAccess) openDropzone();
                  }}
                >
                  <input {...getInputProps()} />
                  <Stack
                    spacing={1}
                    direction={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    height={'100%'}
                  >
                    <Icon icon="arcticons:folder-upload" width="5rem" height="5rem" color="#8dc63f" />
                    <Typography color="text.secondary">Drop invoice or upload file</Typography>
                    <LoadingButton
                      color="secondary"
                      size="large"
                      type="submit"
                      variant="outlined"
                      loading={isSubmitting}
                      onClick={generatePDF}
                      disabled={!hasEditAccess}
                      sx={{
                        p: 1.5,
                        ml: 4,
                      }}
                    >
                      Generate PDF invoice
                    </LoadingButton>
                  </Stack>
                </Box>
              )}
              {!displayMainFile && attachmentErrorFlag && (
                <Box sx={{ width: '100%', maxHeight: '600px' }}>
                  <Box
                    sx={{
                      mb: 1,
                      textAlign: 'center',
                    }}
                  >
                    Failed to load PDF file.
                  </Box>
                </Box>
              )}
              {!invoiceErrorFlag && !attachmentErrorFlag && (
                <Box sx={{ mt: '10px' }}>
                  
                  {/* <PDFViewer blobUrl={displayMainFile ? invoicePhysicalFile : invoicePhysicalAttachment} /> */}
                 
                  <iframe
                    src={displayMainFile ? invoicePhysicalFile : invoicePhysicalAttachment}
                    title="PDF Viewer"
                    width="100%"
                    height="850px"
                    style={{ border: 'none' }}
                  />
                </Box>
              )}
            </div>
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              Loading...
            </Box>
          )}
        </Box>
        {/* </AccordionDetails>
           </Accordion>
         </Box> */}
      </>
    </>
  );
}

const accordionBoxStyle = {
  borderRadius: '4px',
  boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  backgroundColor: '#fff',
  border: 'solid 1px #c1c9d0',
  width: '100%',
  mb: '20px',
};

const accordionStyle = {
  width: '100%',
  '&.Mui-expanded': {
    //minHeight: '700px',
    maxHeight: '700px',
  },
};
