import PropTypes from 'prop-types';
import { useState } from 'react';
import { ScopeGuard } from '../../../../reusable-components/scopes';
import { deleteFacilityContact } from '../../../../api/facility';
import { ConfirmDialog } from '../../../../reusable-components/confirm-dialog';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import EditIconButton from '../../../../reusable-components/basic-buttons/EditIconButton';
import AddButton from '../../../../reusable-components/basic-buttons/AddButton';
import BookingEmailForm from './BookingEmailForm';

BookingEmailTable.propTypes = {
  apContacts: PropTypes.array,
  facilityRow: PropTypes.object,
  bookingType: PropTypes.object,
  fetchData: PropTypes.func,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool
};

export default function BookingEmailTable({ apContacts, fetchData, facilityRow, isLoading, disabled, bookingType }) {
  const { enqueueSnackbar } = useSnackbar();
  const [editRow, setEditRow] = useState();
  const [openForm, setOpenForm] = useState(false);

  const deleteBookingEmail = async (id) => {
    try {
      const response = await deleteFacilityContact(id);
      if (response.status === 200) {
        enqueueSnackbar('Booking email deleted successfully', { variant: 'success' });
        fetchData();
      } else enqueueSnackbar('Something went wrong', { variant: 'error' });
    } catch (error) {
      console.error('Error deleting booking account:', error);
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const fields = [
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: (params) => {
        const { id, email } = params.row;
        return (
          <ScopeGuard scopes={['Super-Admin']}>
            <EditIconButton onClick={() => { setEditRow(params.row); setOpenForm(true); }} />
            <ConfirmDialog
              content={`Are you sure you want to delete booking email - ${email}?`}
              action={() => deleteBookingEmail(id)}
            />
          </ScopeGuard>
        );
      },
      id: 'actions',
    }
  ];

  return <>
    <CustomDataGrid
      gridId="admin-booking-emails"
      boxSX={{ height: bookingType?.useFacilityAccount ? 'calc(50vh - 240px)' : 'calc(100vh - 240px)' }}
      scrollbarHeight={bookingType?.useFacilityAccount ? 50 : 100}
      data={apContacts}
      gridColumns={fields}
      CustomLeftToolbar={() => <AddButton disabled={disabled} onClick={() => setOpenForm(true)}>Add New Email</AddButton>}
      isLoading={isLoading}
      disabled={disabled}
    />
    <BookingEmailForm
      facilityRow={facilityRow}
      editRow={editRow}
      openForm={openForm}
      fetchData={fetchData}
      close={() => { setEditRow(null); setOpenForm(false); }}
    />
  </>;
}
