import { Autocomplete, Checkbox, DialogContentText, Divider, FormControlLabel, Grid, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';
import OrderSearchWithItemTable from './SpecialFieldTypes/OrderSearchWithItemTable';
import OrderSearchOnly from './SpecialFieldTypes/OrderSearchOnly';

TicketTypeFields.propTypes = {
  ticketTypeFieldGroups: PropTypes.array,
  setTicketTypeFieldGroups: PropTypes.func,
  facility: PropTypes.object,
  ticketType: PropTypes.object
};

export default function TicketTypeFields({ ticketTypeFieldGroups, setTicketTypeFieldGroups, facility, ticketType })
{
  const setFieldValue = (originalField, group, inputValue) => {
    setTicketTypeFieldGroups(groups => {
      try {
        const updateGroup = groups.find(updateGroup => updateGroup.groupId === group.groupId);
        const updateField = updateGroup.fields.find(field => field.id === originalField.id);
        Object.keys(inputValue).forEach(key => { updateField[key] = inputValue[key]; });
        if (!updateGroup.repeatGroup || !groups.filter(repeatGroup => repeatGroup.repeatedGroupId === (updateGroup.repeatedGroupId ?? updateGroup.groupId))
          .every(repeatGroup => repeatGroup.fields.some(field => field.value))) return [...groups];
        const repeatedGroup = groups.find(repeatedGroup => repeatedGroup.groupId === (updateGroup.repeatedGroupId ?? updateGroup.groupId));
        let newFieldId = (groups.flatMap(group => group.fields.map(field => field.id)).reduce((prev, current) => (prev > current) ? prev : current, 0));
        let displayHTMLValue = repeatedGroup.displayHTMLValue;
        const newFields = repeatedGroup.fields.map(field => ({ ...field, id: ++newFieldId, repeatedFieldId: field.id, value: null }));
        newFields.forEach(field => { displayHTMLValue = displayHTMLValue.replace(`{${field.repeatedFieldId} - ${field.name ?? ""}}`, `{${field.id} - ${field.name ?? ""}}`) })
        return [...groups, {
          ...repeatedGroup,
          displayHTMLValue,
          repeatedGroupId: repeatedGroup.groupId,
          groupId: (groups.reduce((prev, current) => (prev && prev.groupId > current.groupId) ? prev : current, null)?.groupId ?? 0) + 1,
          fields: newFields
        }];
      } catch {
        return [...groups];
      }
    });
  }

  const displayField = (field, group) => {
    const defaultOpts = {
      size: "small",
      fullWidth: true,
      placeholder: field.name
    };

    switch (field.type) {
      case "Number":
        return <NumericFormat
          {...defaultOpts}
          value={field.value ?? ""}
          customInput={TextField}
          onValueChange={(values) => setFieldValue(field, group, { value: values?.value })}
        />;
      case "Currency":
        return <NumericFormat
          {...defaultOpts}
          customInput={TextField}
          value={field.value ?? ""}
          thousandSeparator
          size="small"
          fullWidth
          decimalScale={2}
          fixedDecimalScale
          prefix="$"
          onValueChange={(values) => setFieldValue(field, group, { value: values?.value })}
        />
      case "Checkbox":
        return <FormControlLabel control={<Checkbox checked={field.value === "Yes"} onChange={(event) => setFieldValue(field, group, { value: event.target.checked ? "Yes" : "No" })} />}
          label={field.name}
        />
      case "Dropdown":
        return <Autocomplete
          fullWidth
          size="small"
          options={field.dropdownOptions || []}
          value={field.dropdownOptions?.find(val => val.value === field.value) || null}
          renderInput={(params) => <TextField {...params} placeholder={field.name} />}
          onChange={(event, newValue) => setFieldValue(field, group, { value: newValue?.value ?? "" })}
          sx={{
            '& legend': { display: 'none' },
            '& fieldset': { top: 0 },
            '& .MuiFormLabel-root ': 'none',
          }}
        />
      case "Order Search":
        return <OrderSearchOnly field={field} facilityId={facility?.id ?? 0} setFieldValue={setFieldValue} group={group} />
      case "Order Search - With Item Table":
        return <OrderSearchWithItemTable field={field} facilityId={facility?.id ?? 0} setFieldValue={setFieldValue} group={group} />
      case "PS Item Number":
        return <NumericFormat {...defaultOpts} allowNegative={false} value={field.value ?? ""} customInput={TextField}
          onValueChange={(values) => setFieldValue(field, group, { value: values?.value })} />;
      case "Link": {
        const isValidLink = !field.value || /^(ftp|http|https):\/\/[^ "]+$/.test(field.value);
        return <TextField
          {...defaultOpts}
          value={field.value ?? ""}
          onChange={(e) => setFieldValue(field, group, { value: e.target.value })}
          error={!isValidLink}
          helperText={isValidLink ? "" : 'Please enter a valid link'}
        />;
      }
      default:
        return <TextField {...defaultOpts} value={field.value ?? ""} onChange={(e) => setFieldValue(field, group, { value: e.target.value })} />
    }
  }

  const showUntil = ticketType.showGroupsInSequence ? ticketTypeFieldGroups.findIndex(group => group.fields.some(field => field.required && !field.value)) : -1;

  return ticketTypeFieldGroups.length > 0 ? <Grid container direction="row" spacing={2}>
    {ticketTypeFieldGroups.filter((group, index) => (showUntil === -1 || index <= showUntil) && group.fields?.some(field => field.name && field.type && field.type !== "No Input Text"))
      .map(group => <Grid item key={group.groupId} xs={12}>
        <Grid container direction="row" spacing={2}>
          {!group.miscellaneousFieldsGroup && !group.repeatedGroupId && <DialogContentText sx={{ width: "100%", ml: 2, mb: -1.9, mt: 1.5 }}>{group.groupLabel}</DialogContentText>}
          {group.fields.filter(field => field.name && field.type && field.type !== "No Input Text").map((field, index) =>
            <Grid item key={index} xs={field.width > 0 ? 12 * (field.width / 100) : 12}>
              {group.miscellaneousFieldsGroup && field.name && <DialogContentText>{field.name}</DialogContentText>}
              {displayField(field, group)}
            </Grid>)}
          {group.repeatGroup && <Grid item xs={12}><Divider sx={{ height: '2px', opacity: 0.39, backgroundColor: '  #707070' }} /></Grid>}
        </Grid>
      </Grid>)}
  </Grid> : "";
}