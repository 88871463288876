import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Autocomplete,
  Grid,
  Typography,
  Box,
  Button,
  TextField,
  DialogActions,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import Iconify from '../../../../reusable-components/iconify';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { copyBudget } from '../../../../redux/slices/budget';
import { useDispatch } from '../../../../redux/store';
import { AwaitButton } from '../../../../reusable-components/await-button';

CopyBudget.propTypes = {
  facilities: PropTypes.array,
  agoraCategories: PropTypes.array,
};

export default function CopyBudget({ facilities, agoraCategories }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const [value, setValue] = useState({
    facility: '',
    facilityCopy: '',
    useGLs: false,
    agoraCategories: [],
  });

  const handleSave = async () => {
    const response = await dispatch(
      copyBudget(value.facility.id, value.facilityCopy.id, value.useGLs, value.agoraCategories)
    );
    if (response) {
      enqueueSnackbar('Copied successfully', { variant: 'success' });
    } else {
      enqueueSnackbar('Failed to copy', { variant: 'error' });
    }
    setOpen(false);
  };

  const handleClickOpen = () => {
    setValue({
      facility: '',
      facilityCopy: '',
      useGLs: false,
      agoraCategories: [],
    });
    setOpen(true);
  };
  const handleClose = () => {
    setValue({
      facility: '',
      facilityCopy: '',
      useGLs: false,
      agoraCategories: [],
    });
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        sx={{ mr: 1 }}
        size="small"
        color="secondary"
        onClick={handleClickOpen}
        startIcon={<Iconify icon="eva:plus-outline" />}
      >
        Copy Budget
      </Button>

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth size="small">
        <DialogTitle
          sx={{
            textAlign: 'center',
          }}
        >
          Copy Budget
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ height: '100%', width: '100%' }}>
          <Grid container direction="row" justifyContent="center" alignItems="flex-start" sx={{ p: 1 }}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              sx={{ mt: 1.5 }}
              spacing={2}
            >
              <Box sx={{ width: '100%' }}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Facility
                </Typography>
                <Autocomplete
                  fullWidth
                  size="small"
                  value={value.facility}
                  onChange={(event, newValue) => {
                    setValue({
                      ...value,
                      facility: newValue,
                    });
                  }}
                  options={facilities?.map((type) => ({
                    id: type.id,
                    label: type.facilityName,
                    facilityBookingTypeId: type.facilityBookingTypeId,
                  }))}
                  renderInput={(params) => <TextField {...params} label="Search or select" />}
                  sx={{
                    mt: 1,
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    '& .MuiFormLabel-root ': { display: 'none' },
                  }}
                />
              </Box>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              sx={{ mt: 1.5 }}
              spacing={2}
            >
              <Box sx={{ width: '100%' }}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Facility to copy from
                </Typography>
                <Autocomplete
                  fullWidth
                  size="small"
                  value={value.facilityCopy}
                  onChange={(event, newValue) => {
                    setValue({
                      ...value,
                      facilityCopy: newValue,
                    });
                  }}
                  options={facilities?.map((type) => ({
                    id: type.id,
                    label: type.facilityName,
                    facilityBookingTypeId: type.facilityBookingTypeId,
                  }))}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  renderInput={(params) => <TextField {...params} label="Search or select" />}
                  sx={{
                    mt: 1,
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    '& .MuiFormLabel-root ': { display: 'none' },
                  }}
                />
              </Box>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              sx={{ mt: 1.5 }}
              spacing={2}
            >
              <Box sx={{ width: '100%' }}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  PS Categories
                </Typography>

                <Autocomplete
                  multiple
                  fullWidth
                  size="small"
                  id="checkboxes-tags-demo"
                  options={[
                    { id: 'selectAll', label: 'Select All' },
                    ...agoraCategories?.map((type) => ({ id: type.id, label: type.name })),
                  ]}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option?.label}
                  value={value.agoraCategories}
                  onChange={(event, newValue) => {
                    if (newValue.some((option) => option.id === 'selectAll')) {
                      // If 'Select All' is the latest selected item, select all; otherwise, deselect all
                      const isSelectAllNewlyAdded =
                        newValue.length === 1 || newValue[newValue.length - 2].id !== 'selectAll';
                      setValue({
                        ...value,
                        agoraCategories: isSelectAllNewlyAdded
                          ? agoraCategories?.map((type) => ({ id: type.id, label: type.name }))
                          : [],
                      });
                    } else if (
                      value.agoraCategories?.findIndex((x) => x.id === newValue[newValue.length - 1].id) > -1
                    ) {
                      setValue({
                        ...value,
                        agoraCategories: value.agoraCategories.filter((x) => x.id !== newValue[newValue.length - 1].id),
                      });
                    } else {
                      setValue({
                        ...value,
                        agoraCategories: newValue,
                      });
                    }
                  }}
                  sx={{
                    mt: 1,
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    '& .MuiFormLabel-root ': { display: 'none' },
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        style={{ marginRight: 8 }}
                        checked={value.agoraCategories?.findIndex((x) => x.id === option.id) > -1}
                      />
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => <TextField {...params} label="Checkboxes" placeholder="Select" />}
                />
              </Box>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              sx={{ mt: 1.5 }}
              spacing={2}
            >
              <Box sx={{ width: '100%' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={value.useGLs}
                      onChange={(event) => {
                        setValue({
                          ...value,
                          useGLs: event.target.checked,
                        });
                      }}
                    />
                  }
                  label="Use GLs"
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={handleClose}>
            Cancel
          </Button>
          <AwaitButton
            variant="contained"
            color="primary"
            size="medium"
            disabled={value.facility === ''}
            sx={{ width: 150 }}
            onClick={handleSave}
          >
            Save
          </AwaitButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
