import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { TextField } from '@mui/material';
import { useDispatch, useSelector } from '../../../../redux/store';
import { addToCart } from '../../../../redux/slices/orderSheet';

QuantityField.propTypes = {
  // product: PropTypes.object,
  row: PropTypes.object,
};

export default function QuantityField({ row }) {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { facilityId = '' } = useParams();
  const categoryId = searchParams.get('categoryId');

  const {
    data: { cart },
  } = useSelector((state) => state.orderSheet);
  const [cartItems, setCartItems] = useState([]);
  const [quantity, setQuantity] = useState();

  useEffect(() => {
    setCartItems(cart);
  }, [cart]);

  const { price, uomAmount, uomType } = row.productDetails[0];

  const handleAddCart = (quantity) => {
    let productToAdd = null;
    const productCategory = row?.productCategories?.find(
      (product) => product?.agoraCategory?.id === parseInt(categoryId, 10)
    );
    if (productCategory)
      productToAdd = {
        agoraCategoryId: productCategory.agoraCategory.id,
        agoraSubcategoryId: productCategory.agoraSubcategory.id,
        agoraSubcategory: productCategory.agoraSubcategory.name,
      };
    else
      productToAdd = {
        agoraCategoryId: row.agoraCategory.id,
        agoraSubcategoryId: row.agoraSubcategory.id,
        agoraSubcategory: row.agoraSubcategory.name,
      };
    if (!productToAdd) {
      return;
    }
    dispatch(
      addToCart({
        id: row.id,
        quantity,
        price,
        uomAmount,
        uomType,
        facilityId,
        categoryId,
        productDetails: row.productDetails,
        ...productToAdd,
      })
    );
  };

  useEffect(() => {
    const product = cartItems.find((item) => item.id === row.id);
    if (product) {
      setQuantity(product.quantity);
    } else {
      setQuantity(0);
    }
  }, [cartItems, row.id]);

  return (
    <>
      <TextField
        size="small"
        value={quantity || ''}
        onChange={(e) => {
          const newValue = Number(e.target.value);
          if (!Number.isNaN(newValue) && newValue >= 0) {
            setQuantity(newValue);
            handleAddCart(newValue);
          }
        }}
        sx={
          {
            // '& legend': { display: 'none' },
            // '& fieldset': { top: 0 },
            // '& .MuiFormLabel-root ': { display: 'none' },
          }
        }
      />
    </>
  );
}
