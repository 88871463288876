import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSearchParams, useParams } from 'react-router-dom';
import { Grid, Box, Divider, Card } from '@mui/material';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { CategoryNavbar } from './order-navbar';
import { BudgetBox } from './index';
import { SHOP_CATALOG } from '../../../../reusable-components/datagrid/shopColumns';
import { useSelector } from '../../../../redux/store';
import QuantityField from './QuantityField';
import { createColumns } from '../../../../reusable-components/datagrid/custom-filters';
import { ScopeGuard, useScopeCheck } from '../../../../reusable-components/scopes';
import { CollapseSidebar } from '../../../../reusable-components/collapse-sidebar';

ShopView.propTypes = {
  data: PropTypes.array,
  setData: PropTypes.func,
};

export default function ShopView({ data, setData }) {
  const hasBudgetViewAccess = useScopeCheck(['View-Order'], true);
  const isCartCheckout = useScopeCheck(['Cart-Check'], true);
  const [searchParams] = useSearchParams();
  const { facilityId = '' } = useParams();
  const id = searchParams.get('categoryId') || null;
  const category = searchParams.get('category') || null;
  const [sortModel, setSortModel] = useState([{ field: 'previouslyOrderedItems', sort: 'desc' }]);
  const [openSidebar, setOpenSidebar] = useState(true);
  const [initialSort, setInitialSort] = useState(true);
  const {
    data: { items, cart, budgets, hasNoBudget },
  } = useSelector((state) => state.orderSheet);
  const isLoading = useSelector((state) => state.orderSheet.isLoading);
  const [budget, setBudget] = useState(null);

  useEffect(() => {
    setData(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);
  useEffect(() => {
    if (data.length > 0 && initialSort) {
      setSortModel([{ field: 'previouslyOrderedItems', sort: 'desc' }]);
      setInitialSort(false);
    }
  }, [data.length, initialSort]);

  useEffect(() => {
    if (!budgets?.length) return;
    setBudget(budgets.find((budget) => budget.category === category));
  }, [budgets, category]);

  const customToolbar = useCallback(() => <CategoryNavbar setData={setData} setSortModel={setSortModel} />, []);

  const quantity = {
    field: 'quantity',
    headerName: 'Qty',
    flex: 0.5,
    minWidth: 80,
    renderCell: (params) => <QuantityField row={params.row} />,
    valueGetter: (params) => {
      const foundProduct = cart.find((item) => item.id === params.row.id);
      return foundProduct ? foundProduct.quantity : 0;
    },
  };
  const vendor = {
    field: 'vendor',
    headerName: 'Vendor',
    flex: 0.8,
    minWidth: 90,
    renderCell: (params) => (
      <Box
        component="div"
        sx={{
          wordWrap: 'break-word',
          whiteSpace: 'pre-wrap',
        }}
      >
        {params.row.productDetails[0].vendor}
      </Box>
    ),
    type: 'customText',
    valueGetter: (params) => params.row.productDetails[0].vendor,
  };

  const mergeColumns = [...SHOP_CATALOG, quantity];

  if (isCartCheckout) {
    mergeColumns.push(vendor);
  }

  const columns = createColumns(mergeColumns);

  return (
    <>
      <Grid item xl={12}>
        <Divider
          flexItem
          sx={{
            height: '2px',
            opacity: 0.39,
            backgroundColor: '  #707070',
            ml: 2,
            mb: 2,
          }}
        />
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
          <Grid item xs={hasBudgetViewAccess ? (openSidebar ? 9.3 : 11.9) : 12}>
            <Grid item xs={12}>
              <CustomDataGrid
                gridId="orders-new-order-shop-view"
                boxSX={{ height: 'calc(100vh - 220px)' }}
                data={data}
                gridColumns={columns}
                sort={sortModel}
                CustomLeftToolbar={customToolbar}
                getRowId={(row) => row.id}
                disableSelectionOnClick
                isLoading={isLoading}
              />
            </Grid>
          </Grid>
          {hasBudgetViewAccess && (
            <Grid item xs={openSidebar ? 2.7 : 0.1} style={{ alignSelf: 'stretch', position: 'relative' }}>
              <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                <CollapseSidebar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar}>
                  {openSidebar && (
                    <>
                      <ScopeGuard scopes={['View-Order']} allowAdmin>
                        <Box>
                          <Grid item xs={12}>
                            {hasNoBudget ? (
                              <Card sx={{ p: 2, ml: 2, mt: 4 }}>No budget set. Contact your admin.</Card>
                            ) : typeof budget === 'object' ? (
                              <Grid item>
                                <BudgetBox cart={cart} catId={parseInt(id, 10)} facId={facilityId} />
                              </Grid>
                            ) : (
                              <Card sx={{ p: 2, ml: 2, mt: 4 }}>Loading budget...</Card>
                            )}
                          </Grid>
                        </Box>
                      </ScopeGuard>
                    </>
                  )}
                </CollapseSidebar>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}
