import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Grid, Button } from '@mui/material';
import PropTypes from 'prop-types';
import CustomDataGrid from '../../../reusable-components/datagrid/CustomDataGrid';
import { useSelector, useDispatch } from '../../../redux/store';
import { useSnackbar } from '../../../reusable-components/snackbar';
import { updateOrderStatus } from '../../../redux/slices/rentals';
import { RENTAL_ORDER_ITEMS } from '../../../reusable-components/datagrid/rentalColumns';

RentalOrderItemsView.propTypes = {
  orderId: PropTypes.number,
  orderStatus: PropTypes.string,
};

export default function RentalOrderItemsView({ orderId, orderStatus }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const {
    data: { pendingRentals },
  } = useSelector((state) => state.rentals);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();

  const rejectOrder = () => {
    dispatch(updateOrderStatus(orderId, 'PREVIOUS'));
    enqueueSnackbar('Rental Order Rejected', { variant: 'error' });
    navigate(`/dashboard/home`);
  };
  const approveOrder = () => {
    dispatch(updateOrderStatus(orderId, 'CURRENT'));
    enqueueSnackbar('Rental Order Approved', { variant: 'success' });
    navigate(`/dashboard/home`);
  };

  useEffect(() => {
    const filteredRows = pendingRentals.filter((item) => item.orderId === orderId);
    const uniqueRows = filteredRows.reduce((acc, current) => {
      const exists = acc.some((item) => item.orderId === current.orderId && item.itemId === current.itemId);
      if (!exists) {
        acc.push(current);
      }
      return acc;
    }, []);

    setRows(uniqueRows);
    setDataLoaded(true);
  }, [pendingRentals, orderId]);

  return (
    <>
      <Grid container alignItems="center" justifyContent="space-between" spacing={1} marginBottom={1}>
        <Grid item>
          <Typography variant="subtitle1" sx={{ ml: 5, color: '#8dc63f', fontWeight: 'bold', fontSize: '15px' }}>
            Rental Order AG{orderId}
          </Typography>
        </Grid>
        {orderStatus === 'Pending' && (
          <Grid item sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              spacing={1}
              alignItems="center"
              sx={{ padding: 1 }}
            >
              <Grid item>
                <Button variant="outlined" color="secondary" onClick={rejectOrder}>
                  Reject Order
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="secondary" onClick={approveOrder}>
                  Approve Order
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>

      <CustomDataGrid
        gridId="pending-rentals"
        boxSX={{ width: '100%', height: 'calc(100vh - 260px)' }}
        data={rows}
        getRowId={(row) => row.itemId}
        gridColumns={RENTAL_ORDER_ITEMS}
        isLoading={!dataLoaded}
        sort={[{ field: 'name', sort: 'asc' }]}
      />
    </>
  );
}
