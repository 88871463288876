import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import CustomDataGrid from '../../../reusable-components/datagrid/CustomDataGrid';
import { getRentalOrdersRedux } from '../../../redux/slices/rentals';
import { useDispatch } from '../../../redux/store';
import {
  PENDING_RENTALS,
  CURRENT_RENTALS,
  PREVIOUS_RENTALS,
} from '../../../reusable-components/datagrid/rentalColumns';

RentalOrdersView.propTypes = {
  orderStatus: PropTypes.string,
};

export default function RentalOrdersView({ orderStatus }) {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      setDataLoaded(false);
      const response = await dispatch(getRentalOrdersRedux(orderStatus.toUpperCase()));
      let groupedItems = response?.reduce((group, item, index) => {
        const { orderId, orderedQty, total } = item;

        if (group[orderId]) {
          group[orderId].total += total;
          group[orderId].orderedQty += orderedQty;
        } else {
          group[orderId] = {
            orderId: item.orderId,
            orderNumber: item.orderNumber,
            orderStatusId: item.orderStatusId,
            facilityName: item.facilityName,
            personFor: item.personFor,
            department: item.department,
            onBehalfOf: item.onBehalfOf,
            submittedBy: item.submittedBy,
            submittedOn: item.submittedOn,
            approvedOn: item.approvedOn,
            approvedBy: item.approvedBy,
            orderedQty: item.orderedQty,
            total: item.total,
          };
        }

        return group;
      }, {});

      groupedItems = groupedItems !== undefined ? Object.values(groupedItems) : groupedItems;
      setRows(groupedItems);
      setDataLoaded(true);
    } catch (error) {
      console.error('Error fetching vendor data:', error);
      setDataLoaded(true);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {}, [rows]);

  let gridColumns;
  if (orderStatus === 'Pending') {
    gridColumns = PENDING_RENTALS;
  } else if (orderStatus === 'Current') {
    gridColumns = CURRENT_RENTALS;
  } else if (orderStatus === 'Previous') {
    gridColumns = PREVIOUS_RENTALS;
  }
  const rowClick = (orderId) => {
    navigate(`/dashboard/rentals/rental-order/${orderId}/${orderStatus}`);
  };

  return (
    <>
      <Typography variant="subtitle1" sx={{ ml: 6, mt: 1, color: '#8dc63f', fontWeight: 'bold', fontSize: '15px' }}>
        {orderStatus} Rentals
      </Typography>
      <CustomDataGrid
        gridId="pending-rentals"
        boxSX={{ width: '100%', height: 'calc(100vh - 260px)' }}
        data={rows}
        getRowId={(row) => row.orderId}
        gridColumns={gridColumns}
        isLoading={!dataLoaded}
        sort={[{ field: 'name', sort: 'asc' }]}
        onRowClick={(params) => {
          const selection = window.getSelection().toString();
          if (selection.length > 0) return;
          rowClick(params.row.orderId);
        }}
      />
    </>
  );
}
